/*------------------------------>
	Components > Block Section
<------------------------------*/

	/* Position background elements breaking out of grid */

	@mixin block-section-breakout {
		width: 100%;
		width: 120vw;
	}

	@mixin block-section-push {
		@include block-section-breakout;

		left: -27px;

		@include breakpoint($min-sm) {
			left: -27px;
		}

		@include breakpoint($min-md) {
			left: 352.5px;

			margin-left: -50vw;
		}

		@include breakpoint($min-lg) {
			
		}
	}

	@mixin block-section-push-left {
		@include block-section-push;

		@include breakpoint($min-lg) {
			left: 470px;
		}

		@include breakpoint($min-xl) {
			left: 595px;
		}
	}

	@mixin block-section-push-right {
		@include block-section-push;

		@include breakpoint($min-lg) {
			left: 70px;
		}

		@include breakpoint($min-xl) {
			left: 87.5px;
		}
	}

	@mixin block-section-list-pull {

		@include breakpoint($min-lg) {
			margin-left: -100vw;
			transform: translateX(380px);
		}

		@include breakpoint($min-xl) {
			transform: translateX(475px);
		}
	}


	/*	Section - Left or Right?
	//------------------------------*/

	.block-section {
		@include clearfix;

		position: relative;

		margin: 30px 0 60px;

		@include breakpoint($min-lg) {
			margin: 40px 0;
		}

		@include breakpoint($min-xl) {
			margin: 55px 0;
		}

		&:first-child {
			margin-top: 22px;
		}

		&:before {
			@include full-width;
			@include bg-lines-diagonal;

			position: absolute;
			top: -20px;

			content: "";
		}
		
		&.block-section-left {
			.fs-cell:first-child {
				.block-footer {
					position: relative;
					z-index: 3;
				}
			}
		}

		&.block-section-right {
			.fs-cell:last-child {
				.block-footer {
					position: relative;
					z-index: 3;
				}
			}
		}

		.cell-lg-left {
			@include breakpoint($min-lg) {
				float: left;
			}
		}
	}

	.block-header {
		position: relative;
		z-index: 1;

		margin-bottom: 56px;
		padding: 10px 0;

		&:before {
			width: 100%;
			height: 100%;

			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;

			background-color: $tan-pale;
			display: block;
			content: "";

			.block-section-right & {
				// include block-section-push-right;
			}

			.block-section-left & {
				@include block-section-push-left;
			}
		}

		&:after {
			@include full-width;
			@include bg-lines-diagonal;

			position: absolute;
			bottom: -20px;

			content: "";

			.block-section-right & {
				// @include block-section-push-right;
			}

			.block-section-left & {
				@include block-section-push-left;
			}
		}
	}

	.block-heading {
		@include page-heading;
		// @include font-size(40);
		// @include verlag-condensed-black;

		color: $gray-light;
		line-height: 1;
		text-transform: uppercase;
		text-shadow: -3px 3px 0 $white;

		@include breakpoint($min-md) {
			// @include font-size(70);

			text-shadow: -4px 4px 0 $white;
		}
		/*
		@include breakpoint($min-lg) {
			@include font-size(80);
		}
		*/
		@include breakpoint($min-xl) {
			@include font-size(76);
		}
		
		@include breakpoint($max-lg) {
			.block-section-right &,
			.block-section-left & {
				text-align: center;
			}
		}
	}

	.block-footer {
		@include breakpoint($min-lg) {
			@include block-section-breakout;
			
			width: 100vw;

			.block-section-left & {
				@include block-section-list-pull;

				width: 100vw;

				text-align: right;
			}
		}
	}

	.block-footer-content {
		position: relative;

		background-color: $red;
		transition: background-color $default-speed ease,
					margin           $default-speed ease-out;

		&:after {
			position: absolute;
			top: 0;

			border-bottom: 30px solid $red;
			border-top: 30px solid $red;
			content: "";
			display: block;
			transition: border $default-speed ease-out, box-shadow $default-speed ease-out;

			.block-section-right & {
				border-left: 0 solid transparent;
				right: 100%;
			}

			.block-section-left & {
				border-right: 0 solid transparent;
				left: 100%;
			}
		}

		.no-touchevents &:hover {
			background-color: $black-gradient;

			&:after {
				border-top-color: $black-gradient;
				border-bottom-color: $black-gradient;
			}
		}

		.no-touchevents .block-section-right &:hover {
			box-shadow: -10px 10px 15px rgba(0,0,0,0.3);

			&:after {
				border-left-width: 20px;
			}

			@include breakpoint($min-lg) {
				margin-left: -40px;
			}
		}

		.no-touchevents .block-section-left &:hover {
			box-shadow: 10px 10px 23px rgba(0,0,0,0.3);

			&:after {
				border-right-width: 20px;
			}

			@include breakpoint($min-lg) {
				margin-right: -40px;
			}
		}
	}

	.block-footer-link {
		@include verlag-condensed-black;
		@include font-size(18);
		@include font-smoothing;

		height: 60px;

		color: $white;
		display: block;
		padding: 20px 25px;
		text-transform: uppercase;

		@include breakpoint($max-md) {
			text-align: center;
		}
	}


	/* Block List */

	.block-list {
		@include breakpoint($min-lg) {
			@include block-section-breakout;
			width: 100vw;

			.block-section-left & {
				@include block-section-list-pull;
				width: 100vw;
			}
		}
	}

	.block-list-item {
		padding: 10px;

		transition: background-color $default-speed ease,
					padding          $default-speed ease;

		&:nth-child(odd) {
			background: $tan-pale;
		}

		.block-section-left & {
			@include breakpoint($min-lg) {
				text-align: right;
			}
		}

		.no-touchevents .block-section-right &:hover {
			@include breakpoint($min-lg) {
				padding-left: 30px;
			}
		}

		.no-touchevents .block-section-left &:hover {
			@include breakpoint($min-lg) {
				padding-right: 30px;
			}
		}

		.no-touchevents &:hover {
			.block-list-item-title {
				color: $red;
			}
		}
	}

	.block-list-item-content {
		@include breakpoint($min-lg) {
			width: 375px;

			text-align: left;
		}

		@include breakpoint($min-xl) {
			width: 485px;
		}
	}

	.block-list-item-title {
		@include verlag-condensed-black;
		@include font-size(27);
		@include transition-link;
		@include font-smoothing;

		color: $gray-darkish;
		line-height: 1.1;
		text-transform: uppercase;

		@include breakpoint($max-md) {
			@include font-size(24);
		}

		@include breakpoint($max-sm) {
			@include font-size(20);
		}
	}

	.block-section-featured {
		position: relative;
		z-index: 2;
	}
