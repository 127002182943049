/*------------------------------>
	Layout > Page
<------------------------------*/

	/*	Age Verification
	//------------------------------*/

	.age-verification {
		position: fixed;

		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;

		background-color: $tan-base;
		background-size: cover;
		background-position: center;
	}

	.age-verification-container {
		@include full;

		position: absolute;
		top: 0;
		left: 0;

		display: table;
	}

	.age-verification-content {
		display: table-cell;
		text-align: center;
		vertical-align: middle;
	}

	.age-verification-wrapper {
		max-width: 500px;
		margin: 0 auto;

		h2 {
			@include heading-2;

			color: $black;
			margin-bottom: 40px;
		}
	}

	.age-verification-button {
		background-color: $red;

		&:hover {
			background-color: $black;
		}
	}


	/*	Page
	//------------------------------*/

	.page-wrapper {
		// overflow-x: hidden;
	}

	.page {
		@include bg-tan-light;
		
		background-color: $off-white;
		outline: none;
		padding: 20px 0 30px;

		@include breakpoint($min-sm) {
			padding: 40px 0;
		}

		@include breakpoint($min-lg) {
			padding: 55px 0;
		}
	}

	.page-intro {
		margin-bottom: 50px;

		@include breakpoint($min-md) {
			margin-bottom: 65px;
		}

		@include breakpoint($min-lg) {
			margin-bottom: 80px;	
		}

		p, a {
			@include verlag;
			@include font-size(24);
			@include line-height(30, 44);

			

			@include breakpoint($min-lg) {
				@include font-size(28);
			}
		}

		p {
			color: $gray-middle;
		}

		a {
			color: $red;
		}
	}


	/* Layouts */

	// Products Page

	.layout-no-page-header .page {
		padding-top: 120px;

		@include breakpoint($min-sm) {
			padding-top: 120px;
		}

		@include breakpoint($min-md) {
			padding-top: 190px;	
		}
		
	} // account for the missing page header


	// Shop Cart 

	.template-cart .page {
		padding-top: 100px;

		@include breakpoint($min-md) {
			padding-top: 170px;
		}
	}

	// Base Template Components

	.template-base {

		.block-section {

			@include breakpoint($min-lg) {
				margin-top: 80px;
			}
		}
	}

	// Pagination

	.pagination {
		border-top: 1px solid $gray-light;
		margin-top: 30px;
		padding: 15px 0 0;
		text-align: center;

		a, span {
			margin: 5px 2px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.pagination-page {
		@include size(44);
		@include verlag-black;
		@include font-size(16);
		@include font-smoothing;

		display: inline-block;
		line-height: 44px;
		text-align: center;

		&.pagination-link {
			background-color: $red;
			color: $white;

			&:hover {
				background-color: $black;
			}
		}

		&.pagination-current,
		&.pagination-spacer {
			background-color: $gray-dark;
			color: $white;
		}
	}

	.pagination-button {
		@include verlag-black;
		@include font-size(16);
		@include font-smoothing;

		height: 44px;

		background-color: $red;
		color: $white;
		display: inline-block;
		line-height: 44px;
		padding: 0 15px;
		text-transform: uppercase;

		&:hover {
			background-color: $black;
		}
	}

	@include breakpoint($max-sm) {
		.pagination-page:not(.pagination-current) {
			display: none;
		}

		.pagination-current {
			margin: 0 auto;
		}

		.pagination-previous {
			float: left;
		}

		.pagination-next {
			float: right;
		}
	}


	// 404

	.template-404 {
		border-top: 30px solid $red;
		
		&,
		.page-wrapper,
		.page {
			@include full;
		}

		.page {
			@include bg-tan;

			display: table;
			overflow-y: auto;
		}
	}

	.page-404 {
		@include full-width;

		position: relative;
		z-index: 1;

		display: table-cell;
		text-align: center;
		vertical-align: middle;

		img {
			max-width: 80%;
			max-height: 65vh;
			margin: 0 auto 20px;

			@include breakpoint($max-sm) {
				max-width: 60%;
				max-height: 50vh;
			}
		}

		h2 {
			@include font-smoothing;

			color: $gray-dark;

		}

		.page-404-button {
			background-color: $red;
			margin-top: 20px;

			&:hover {
				background-color: $gray-dark;
			}
		}

		&:before {
			width: 90%;
			height: 90%;

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;

			background: url(../images/site/404watermark.png) center center no-repeat;
			background-size: contain;
			content: "";
			margin: 0 auto;
		}
	}

