/*------------------------------>
	Layout > Header
<------------------------------*/

	.header {
		@include full-width;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;

		.template-cart &,
		.layout-no-page-header & {
			&:before {
				@include full-width;

				@include gradient-vertical(transparentize(#d9d9c2, 0), transparentize(#d9d9c2, 1))
				
				height: 120px;

				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;

				content: "";
				display: block;
			}
		}
	}


	/*	Header Bar
	//------------------------------*/

	.header-wrapper {
		height: 68px;

		position: relative;
		z-index: 2;

		background-color: $white;
		box-shadow: 0 0 0 3px $white,
					0 0 0 4px $gray-pale,
					0 4px 5px 0 $gray-middle;
		border-bottom: 2px solid $gold;
		border-top: 2px solid $gold;
		margin-top: 13px;

		.template-cart &,
		.layout-no-page-header & {
			&:before,
			&:after {
				@include full-width;

				position: absolute;
				left: 0;
				z-index: -1;

				content: "";
			}

			&:before {
				height: 11px;

				bottom: -30px;

				background-color: #dddac6;


			}

			&:after {
				@include bg-dots;

				height: 10px;

				bottom: -70px;
			}	

			@include breakpoint($max-md) {
				&:before {
					height: 4px;
					bottom: -15px;
				}

				&:after {
					bottom: -25px;
				}
			}
		}

		@include breakpoint($max-md) {
			height: 46px;

			margin-top: 8px;

			.fs-row {
				width: 98%;
			}
		}
	}

	.header-content {
		
	}

	a.header-badge {
		width: 108px;
		height: auto;
		min-height: 160px;

		position: absolute;
		z-index: 1;

		background-color: $red;
		display: block;
		margin-top: -15px;
		transition: min-height 0.2s ease;

		.no-touchevents &:hover {
			@include breakpoint($min-md) {
				min-height: 140px;
			}
			@include breakpoint($min-xl) {
				min-height: 180px;	
			}
		}
	}

	.header-badge-logo {
		@include header-badge;

		position: absolute;
		bottom: 16px;
		left: 15px;

		display: block;
	}

	@include breakpoint($max-md) {
		
		a.header-badge {
			width: 55px;
			min-height: 75px;
		}

		.header-badge-logo {
			@include scale-sprite(0.5, 0, -100px);
			@include size(39, 52);

			bottom: 7px;
			left: 7px;
		}
	}

	@include breakpoint($md-xl) {
		
		a.header-badge {
			width: 85px;
			min-height: 120px;
		}

		.header-badge-logo {
			@include scale-sprite(0.8, 0, -100px);
			@include size(62, 83);

			bottom: 10px;
			left: 10px;
		}
	}

	.header-logo-container {
		position: relative;

		border-right: 2px solid $gold;
		margin-right: 3px;

		&:after {
			@include full-height;

			width: 3px;

			position: absolute;
			top: 0;
			right: -5px;

			background-color: $white;
			border-right: 1px solid $gold;
			content: "";
		}

		@include breakpoint($min-lg) {
			margin-right: 5px;
		}

		@include breakpoint($min-xl) {
			margin-right: 18px;
		}
	}

	.header-logo {
		@include size(290, 64);
		@include text-indent(64px);

		position: relative;

		display: inline-block;
		margin-left: 108px;
		padding-left: 20px;
		padding-right: 20px;

		&:after {
			@include header-logo;

			position: absolute;
			top: 15px;
			left: 20px;

			display: inline-block;
			content: "";
			line-height: 0;
			text-indent: 0;

			@include breakpoint($min-xl) {
				left: 30px;
			}
		}

		@include breakpoint($max-md) {
			@include size(175, 42);

			margin-left: 55px;

			&:after {
				@include scale-sprite(0.6);
				@include size(150, 22);

				top: 10px;
				left: 15px;
			}
		}

		@include breakpoint($md-xl) {
			width: 240px;

			margin-left: 85px;
			
			&:after {
				@include scale-sprite(0.8);
				@include size(200, 30);

				top: 17px;
				left: 22px;
			}
		}

		@include breakpoint($min-xl) {
			width: 310px;

			padding-left: 30px;
			padding-right: 30px;
		}
	}
