/*------------------------------>
	Document > Text
<------------------------------*/
	
	/*	Font Mixins 
	//------------------------------*/

	@mixin verlag($font-style: normal, $font-weight: 400) {
		font-family: "Verlag A", "Verlag B", Helvetica, Arial, sans-serif;
		font-style: $font-style;
		font-weight: $font-weight;
	}

	@mixin verlag-condensed($font-style: normal, $font-weight: 400) {
		font-family: "Verlag Cond A", "Verlag Cond B", Helvetica, Arial, sans-serif;
		font-style: $font-style;
		font-weight: $font-weight;
	}

	@mixin verlag-condensed-bold {
		@include verlag-condensed(normal, 700);
	}

	@mixin verlag-condensed-black {
		@include verlag-condensed(normal, 800);
	}

	@mixin verlag-italic {
		@include verlag(italic, 400);
	}

	@mixin verlag-bold {
		@include verlag(normal, 700);
	}
	
	@mixin verlag-black {
		@include verlag(normal, 800);
	}

	@mixin verlag-black-italic {
		@include verlag(italic, 800);
	}


	/*	Text Styles
	//------------------------------*/

	@mixin heading-1 {
		@include verlag-condensed-black;
		@include font-size(66);

		line-height: 1.2;
		text-transform: uppercase;

		@include breakpoint($max-md) {
			@include font-size(56);
		}

		@include breakpoint($max-sm) {
			@include font-size(44);
		}
	}

	.heading-1 {
		@include heading-1;
	}

	@mixin heading-2 {
		@include verlag-condensed-black;
		@include font-size(48);

		color: $red;
		line-height: 1.2;
		text-transform: uppercase;

		@include breakpoint($max-md) {
			@include font-size(40);
		}

		@include breakpoint($max-sm) {
			@include font-size(32);
		}
	}

	.heading-2 {
		@include heading-2;
	}

	@mixin heading-3 {
		@include verlag-condensed-black;
		@include font-size(36);

		color: $red;
		line-height: 1.2;
		text-transform: uppercase;

		@include breakpoint($max-md) {
			@include font-size(30);
		}

		@include breakpoint($max-sm) {
			@include font-size(26);
		}
	}

	.heading-3 {
		@include heading-3;
	}

	@mixin heading-4 {
		@include verlag-condensed-black;
		@include font-size(30);

		color: $red;
		line-height: 1.2;
		text-transform: uppercase;

		@include breakpoint($max-md) {
			@include font-size(24);
		}

		@include breakpoint($max-sm) {
			@include font-size(22);
		}
	}

	.heading-4 {
		@include heading-4;
	}

	@mixin heading-5 {
		@include verlag-condensed-black;
		@include font-size(24);

		color: $gray-dark;
		line-height: 1.2;
		text-transform: uppercase;

		@include breakpoint($max-md) {
			@include font-size(20);
		}

		@include breakpoint($max-sm) {
			@include font-size(18);
		}
	}

	.heading-5 {
		@include heading-5;
	}

	@mixin heading-6 {

	}

	.heading-6 {
		@include heading-6;
	}

	@mixin navigation-link {
		
	}

	@mixin blockquote {
		@include verlag-condensed-black;
		@include font-size(24);
		@include line-height(30, 44);
		@include clearfix;

		min-height: 65px;

		position: relative;

		padding-left: 100px;
		text-transform: uppercase;

		@include breakpoint($min-md) {
			@include font-size(28);
		}

		@include breakpoint($min-md) {
			@include font-size(30);
		}
		
		&:before {
			@include verlag-black;
			@include font-size(140);

			height: 100%;
			min-height: 65px;

			position: absolute;
			left: 0;

			border-right: 3px solid $gray-border;
			color: $red;
			content: open-quote;
			float: left;
			line-height: 140px;
			padding-right: 20px;
		}
	}

	@mixin page-intro {
		@include verlag-condensed-black;
		@include font-size(24);
		@include line-height(30, 44);
	}

	@mixin body-text {
		@include font-size(18);
		@include line-height(24, 38.5);

		@include breakpoint($min-md) {
			@include font-size(20);
		}

		@include breakpoint($min-xl) {
			@include font-size(24);
		}
	}

	@mixin page-heading {
		@include heading-1;
		@include font-size(36);

		color: $gray-light;
		line-height: 1;

		@include breakpoint($min-sm) {
			@include font-size(50);
		}

		@include breakpoint($min-lg) {
			@include font-size(66);
		}

		@include breakpoint($min-xl) {
			@include font-size(66);
		}
	}

	@mixin link {
		@include verlag-bold;

		position: relative;
		z-index: 1;

		background-image: linear-gradient(to bottom,$gray-border 50%,transparentize($gray-border, 1) 50%);
		background-size: 3px .2em;
		background-position: 0 1.07em;
		background-repeat: repeat-x;
		color: $red;
		display: inline;
		text-shadow: 1px 1px 0 $tan-pale, 
					-1px 1px 0 $tan-pale, 
					2px 0 0 $tan-pale, 
					-2px 0 0 $tan-pale;

		&:hover {
			color: $black;
		}

		@include breakpoint($max-md) {
			background-size: 3px .3em;
		}
	}

	@mixin button {
		
		&:hover {

		}
	}


	/*	Text Elements
	============================================================================= */

	a {
		@include transition-link;

		text-decoration: none;
	}

	h1, h2, h3, h4, h5, h6, p, blockquote {
		font-weight: 400;
		margin: 0;
	}

	// WYSIWYG text styles

	.rich-text {

		* {
			margin-bottom: 0;
		}

		* + * {
			margin-top: 1.2em;

			@include breakpoint($min-md) {
				margin-top: 1.5em;
			}

			@include breakpoint($min-lg) {
				margin-top: 1.667em;
			}
		}

		p, ul li, ol li {
			@include body-text;
		}

		a {
			@include body-text;
			@include link;
		}

		h1, h2, h3, h4, h5, h6 {
			
			a {
				border-bottom: none;
			}

			+ p {
				margin-top: 1em;
			}
		}

		hr {
			
		}

		em {
			
		}

		strong {
			
		}

		img {
			height: auto;
		}

		p, h2, h3, h4, h5, h6 {
			
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1 {
			@include heading-1;

			@include breakpoint($min-lg) {
				margin-top: 15px;
			}
		}

		h2 {
			@include heading-2;
		}

		h3 {
			@include heading-3;
		}

		h4 {
			@include heading-4;
		}

		h5 {
			@include heading-5;
		}

		h6 {
			@include heading-6;
		}

		blockquote {

			&,
			p {
				@include blockquote;
			}

			p {
				margin: 0;
			}
		}

		ol,
		ul {
			li {
				margin-top: 10px;
			}

			&:not(.list-style-none)	{
				
				li {
					
				}	
			}
			

			&.list-style-none {
				padding: 0;

				li {
					margin: 0;
				}
			}
		}

		ol {
			counter-reset: li;

			> li {
				position: relative;
				display: block;
				list-style: none;

				&:before {
					position: absolute;
					top: 0;
					right: 100%;
					z-index: 1;

					color: $red;
					content: counter(li) ". ";
					counter-increment: li;
					margin-right: 15px;
				}
			}

			&[type="a"] {
				> li {
					&:before {
						content: counter(li, lower-alpha) ". ";
					}
				}
			}

			&[type="A"] {
				> li {
					&:before {
						content: counter(li, upper-alpha) ". ";
					}
				}
			}

			&[type="i"] {
				> li {
					&:before {
						content: counter(li, lower-roman) ". ";
					}
				}
			}

			&[type="I"] {
				> li {
					&:before {
						content: counter(li, upper-roman) ". ";
					}
				}
			}
		}

		ul {
			list-style: none;
			padding-left: 30px;

			&:not(.list-style-none) {

				li {
					position: relative;

					&:before {
						@include size(6);

						background-color: $red;
						border-radius: 100%;
						content: "";
						display: inline-block;
						margin: auto 0;
						vertical-align: middle;
						position: absolute;
						top: 0.7em;
						left: -20px;
					}
				}
			}
		}

	} // .wysiwyg


	/*	Table Styles
	============================================================================= */

	table {

		&.table-default,
		.rich-text & {

			@include verlag-condensed-black;

			border: 2px solid $black;
			color: $black;
			text-transform: uppercase;

			a {
				color: $black;

				&:hover {
					color: $red;
				}
			}

			thead {
				background-color: $black;
				color: $white;

				th {
					@include verlag-black;
					@include font-size(14);
					@include font-smoothing;

					padding: 12px 20px;
					text-align: left;

					@include breakpoint($min-lg) {
						padding: 12px 24px;
					}
				}
			}

			tbody {
				background-color: $tan-pale;

				tr {

					&:last-child {
						td {
							border-bottom: none;
						}
					}

					&:hover {
						td {
							background-color: $white;
						}
					}
				}

				td {
					@include font-size(17);

					border-bottom: 2px solid $black;
					padding: 14px 20px;
					transition: background 0.2s linear;

					@include breakpoint($min-lg) {
						@include font-size(20);

						padding: 16px 24px;
					}
				}
			}

			&.js-table-sort {

				.tablesorter-header {
					position: relative;

					cursor: pointer;
					outline: none;

					.tablesorter-header-inner:after {
						@include size(0);

						position: absolute;
						top: 0;
						bottom: 0;

						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						content: "";
						margin: auto 0 auto 10px;
					}

					&.tablesorter-headerDesc .tablesorter-header-inner:after {
						border-top: 6px solid $gray-middle;
					}

					&.tablesorter-headerAsc .tablesorter-header-inner:after {
						border-bottom: 6px solid $gray-middle;
					}
				}

			}
		}
	}
