/*------------------------------>
	Templates > Shop
<------------------------------*/


	form.customer-form {
		fieldset {
			margin-bottom: 16px;
		}

		label {
			@include verlag-bold;
			@include font-size(14);

			color: $gray-darkish;
			display: block;
			margin-bottom: 4px;
			text-transform: uppercase;
		}
		
		input[type=text],
		input[type=password],
		input[type=email],
		input[type=date],
		input[type=tel],
		input[type=url],
		input[type=search] {
			width: 100%;
		}
	}

	.store-label {
		@include verlag-bold;
		@include font-size(14);

		color: $gray-darkish;
		display: block;
		margin: 15px 0;
		text-transform: uppercase;
	}
	

	/*	Products Listing
	//------------------------------*/

	.products {

	}

	.product-category-body {
		margin-bottom: 40px;
	}

	.products-section-header {
		margin-bottom: 80px;
	}

	.products-section-heading {
		text-align: center;
	}

	.product-listing {
		margin-bottom: 40px;

		@include breakpoint($sm-md) {
			&:nth-child(2n+1) {
				clear: left;
			}
		}

		@include breakpoint($min-md) {
			&:nth-child(3n+1) {
				clear: left;
			}
		}

		&:hover {

			.product-listing-heading {

				@include breakpoint($min-md) {
					transform: translateY(15px);
				}
			}
		}

		@include breakpoint($max-sm) {
			margin-bottom: 20px;
		}
	}

	.product-listing-heading {
		@include heading-4;

		color: $red;
		margin: 20px 0;
		text-align: center;
		transition: transform 0.25s ease-out;

		@include breakpoint($sm-lg) {
			@include font-size(24);
		}

		@include breakpoint($max-sm) {
			@include font-size(20);
		}
	}

	.product-card.card {

		&:after {
			content: none;
		}
	}
	

	/*	Single Product
	//------------------------------*/

	.product {
		position: relative;
		z-index: 1;
	}

	.product-food {
		.product-description {
			margin-top: 40px;
		}

		.product-options {
			border-top: none;
			border-bottom: 2px solid $gray-light;
			padding-bottom: 40px;
		}
	}

	.product-images {
		padding-bottom: 30px;

		@include breakpoint($min-md) {
			padding-bottom: 50px;
		}
	}

	.product-image-wrapper {
		// padding-bottom: 30px;

		// @include breakpoint($min-md) {
		// 	padding-bottom: 50px;
		// }
	}

	.product-image-decoration {
		position: relative;
		z-index: 1;

		background-color: $gray-dark;

		&:before,
		&:after {
			position: absolute;

			content: "";
			display: block;
		}

		&:before {
			top: 7px;
			left: 7px;
			bottom: 7px;
			right: 7px;
			z-index: 1;

			border: 3px solid #555;
			pointer-events: none;
		}
	}

	.product-image {
		background-color: $gray-dark;
		background-size: cover;

		

		// &:after {
		// 	@include full-width;
		// 	@include gradient-vertical(rgba(38, 38, 38, 0), rgba(38, 38, 38, 1));

		// 	height: 50%;

		// 	bottom: 0;
		// 	left: 0;
		// 	z-index: -2;
		// }
	}

	.product-image-container {
		position: relative;
		
		display: block;
		padding-top: 100%;
	}

	.product-thumbnails-wrapper {
		margin: 20px -10px;
	}

	.product-thumbnails-carousel {
		&.fs-carousel.fs-carousel-enabled.fs-light {
			.fs-carousel-pagination {
				margin: 20px 0 0;
			}

			.fs-carousel-page {
				background-color: $red;
			}
		}
	}

	.product-thumbnails-image {
		cursor: pointer;
		padding: 0 10px;
	}

	.product-content {
		@include breakpoint($min-md) {
			padding-right: 40px;
		}
	}


	.product-header {
		padding-bottom: 60px;

		&:after {
			@include full-width;
			@include bg-lines-vertical;

			width: 200%;
			height: 10px;

			position: absolute;
			left: -50%;
			z-index: -1;
			// bottom: -20px;

			content: "";
			margin-top: 20px;
		}
	}

	.product-heading {
		@include page-heading;

		color: $gray-light;
		line-height: 1;
		margin-bottom: 15px;
	}

	.product-description {
		margin-bottom: 40px;

		p, ul li, ol li, a {
			@include font-size(18);

			@include breakpoint($min-lg) {
				@include font-size(20);
			}
		}
	}

	.product-options {
		border-top: 2px solid $gray-light;
		padding-top: 20px;
	}

	.product-label {
		@include verlag-black;

		color: $black;
		display: inline-block;
		margin-bottom: 8px;
		text-transform: uppercase;
	}

	.product-select {
		margin-bottom: 15px;
	}

	.product-price {
		background: darkgreen;

		.price-disabled {
			color: rgba(255, 255, 255, 0.5);
			text-decoration: line-through;
			margin-right: 6px;
		}

		&.js-price {

			&.hidden {
				display: none;
			}
		}
	}

	.product-add-to-cart {
		margin-top: 15px;
	}

	.add-to-cart-button {
		background-color: $red;
		display: inline-block;
		vertical-align: middle;

		&:hover {
			background-color: $black;
		}
	}

	.product-faq-link {
		margin-top: 20px;

		@include breakpoint($min-xl) {
			display: inline-block;
			margin: 0 0 0 30px;

			&, a {
				vertical-align: middle;
			}
		}
	}

	.product-quantity {

		input {

			@include breakpoint($min-lg) {
				width: 100%;	
			}
		}

		label {
			@include verlag-bold;
			@include font-size(14);

			color: $gray-darkish;
			display: block;
			margin-top: 10px;
			text-transform: uppercase;
		}
	}

	.product-note {
		margin-top: 1em;

		textarea {
			width: 100%;

			display: block;
		}

		label {
			@include verlag-bold;
			@include font-size(14);

			color: $gray-darkish;
			display: block;
			margin-top: 10px;
			text-transform: uppercase;
		}
	}


	/*	Cart
	//------------------------------*/

	.cart-items {

	}

	/* Line Item */

	.cart-item {
		padding: 25px;

		&:nth-child(even) {
			background-color: $tan-base;
		}
	}

	.cart-item-info {
		overflow: hidden;
	}

	.cart-item-name {
		@include verlag-condensed-bold;
		@include font-size(24);

		margin-bottom: 6px;

		@include breakpoint($max-md) {
			@include font-size(20);
		}

		@include breakpoint($max-sm) {
			@include font-size(18);
		}
	}

	.cart-item-sku {
		@include verlag-black;
		@include font-size(14);

		color: $gray-middle;
		text-transform: uppercase;
	}

	.cart-item-price {
		@include verlag-bold;
		@include font-size(20);

		color: $gray-dark;

		.new-price {
			color: $green;
		}
	}

	.cart-item-image {
		width: 100px;

		@include breakpoint($min-md) {
			float: left;
			margin-right: 25px;
		}
	}

	.cart-actions {
		margin-top: 40px;

		table {
			width: 100%;

			tr {

				td {
					@include font-size(20);

					border-bottom: 2px solid $tan-base;
					padding: 16px 0 10px;

					&:first-child {
						padding-right: 16px;
					}

					@include breakpoint($max-md) {
						@include font-size(18);
					}

					@include breakpoint($max-sm) {
						@include font-size(16);
					}
				}

				&:last-child {
					td {
						border-bottom-color: $gray-darkish;

						strong {
							font-weight: 800 !important;
						}
					}
				}
			}
		}
	}

	.cart-remove {
		margin-top: 1em;

		.fs-checkbox {
			display: inline-block;
			vertical-align: middle;
		}
		
		strong {
			display: inline-block;
			vertical-align: top;
		}
	}

	.estimate-show-fields,
	.cart-button-coupon,
	.cart-button-update,
	.cart-button-remove {
		@include button;
		@include verlag-black;
		@include font-size(14);

		text-transform: uppercase;

		&:hover {
			color: $red;
		}
	}

	.estimate-show-fields,
	.cart-button-update {
		color: $red;

		&:hover {
			color: $gray-darkish;
		}
	}

	.cart-button-remove {
		color: $red;
		margin-top: 20px;

		&:hover {
			color: $red-bright;
		}
	}

	.cart-button-empty  {
		background-color: $gray-darkish;
		margin-top: 5px;

		&:hover {
			background-color: $red;
		}
	}

	.cart-button-checkout {
		background-color: $red;
		margin-top: 1.5em;

		&:hover {
			background-color: $green;
		}
	}

	.cart-button-coupon {
		margin-top: 5px;
	}

	.cart-section-coupon {
		// text-align: right;

		label {
			@include verlag-bold;
			@include font-size(14);

			color: $gray-darkish;
			display: block;
			margin-top: 10px;
			text-transform: uppercase;
		}

		#couponCode {
			width: 100%;
		}

		.flash {
			@include verlag-black;
			@include font-size(12);

			color: $red;
			margin-top: 5px;
			text-transform: uppercase;
		}
	}

	.cart-adjustments {
		border-top: 4px solid $gray-dark;
		padding: 0 0 45px;
	}

	.cart-adjustment {
		border-bottom: 2px solid $tan-base;
		padding: 16px 25px;

		h3 {
			font-weight: 700;
			display: inline;
		}
	}

	/* Shipping + Tax Estimates */

	.cart-shipping {
		margin-top: 2em;

		h5 {
			@include heading-5;

			margin-bottom: 16px;
		}
	}

	.estimate-billing {
		// margin-top: 1em;
	}

	.estimate-show-fields {
		margin-top: 1em;
	}


	/*	Checkout
	//------------------------------*/

	.checkout-header {
		margin-bottom: 0;
	}

	.checkout-errors {
		padding: 0 0 30px;
		text-align: center;

		.flash {
			@include verlag-bold;
			@include font-size(16);

			background-color: $red;
			color: $white;
			padding: 10px 20px;
			text-transform: uppercase;
		}
	}

	/* Forms */

	.checkout-form-heading {
		@include heading-5;

		margin-bottom: 20px;
	}

	.checkout-email-fieldset {
		margin-bottom: 24px;
	}

	.template-checkout {

		.help-block {
			display: block;
			color: $red-bright;
			padding: 5px 0 0 5px;
			text-transform: uppercase;
		}
	}


	/* Progress Meter */

	.checkout-progress-steps {
		border: 2px solid $tan-border;
		border-radius: 5px;
		margin-bottom: 24px;
		padding: 3px;

		@include breakpoint($min-md) {
			margin-bottom: 55px	
		}

		ul {
			@include reset-list;

			border-radius: 4px;
			overflow: hidden;

			li {
				position: relative;

				display: inline-block;

				&:nth-child(1) {
					z-index: 4;
				}

				&:nth-child(2) {
					z-index: 3;
				}

				&:nth-child(3) {
					z-index: 2;
				}

				&:nth-child(4) {
					z-index: 1;
				}

				a {
					@include verlag-condensed-black;
					@include font-smoothing;
					@include font-size(20);

					height: 50px;

					background-color: $tan-base;
					color: $gray-dark;
					display: block;
					line-height: 50px;
					padding: 0 24px;
					text-align: center;
					text-transform: uppercase;

					&:after {
						@include size(0);
						@include transition-link;

						position: absolute;
						left: 100%;
						top: 0;
						z-index: 1;

						content: "";
						border-top: 25px solid transparent;
						border-bottom: 25px solid transparent;
						border-left: 20px solid $tan-base;

						transition: border-color $default-speed linear;
					}

					@include breakpoint($max-md) {
						@include font-size(14);

						padding: 0 14px;

					}

					@include breakpoint($max-sm) {
						@include font-size(11);

						&:after {
							content: none;
						}
						padding: 0 10px;
					}
				}

				&.done {

					a {
						background-color: $gray-darkish;
						color: $gray-pale;

						&:after {
							border-left-color: $gray-darkish;
						}
					}
				}

				&.sel {

					a {
						position: relative;

						background-color: $red;
						color: $white;

						&:after {
							border-left-color: $red;
						}
					}
				}

				a:hover {
					background-color: $red;
					color: $white;

					&:after {
						border-left-color: $red;
					}
				}
			}
		}
	}

	#gateway {

		select.form-control {
			@include verlag-black;

			-webkit-appearance: none;

			height: 50px;

			font-size: 14px;
		    line-height: 40px;
		    margin: 0;
		    padding: 0 40px 0 15px;

			background-color: $gray-light;
			border: 1px solid $gray-light;
			border-radius: 0;
			color: $black;
			outline: none;
			text-transform: uppercase;
			transition: border-color 0.2s linear;
			white-space: nowrap;
		}
	}


	/* Account */

	.checkout-account {

	}

	.checkout-user-form,
	.checkout-guest-form {
		margin-top: 40px;
	}

	.checkout-registered-form {

		input[type=submit] {
			background-color: $red;

			&:hover {
				background-color: $black;
			}
		}
	}

	.checkout-guest-form {
		input[type=submit] {

			&:hover {
				background-color: $red;
			}
		}
	}
	
	/* Addresses */

	.checkout-addresses {
		.address-confirm {
			background-color: $red;

			&:hover {
				background-color: $black;
			}
		}
	}

	.address-select {
		margin-bottom: 40px;

		&.address-existing {
			.fs-checkbox-marker {
				margin-top: 6px;
			}

			.address-data {
				padding-left: 10px;
			}
		}

		.actions {
			margin-bottom: 30px;

			.edit {
				&:hover {
					background-color: $red;
				}
			}
		}

		.body {
			margin-top: 30px;
		}
	}

	.address-data {
		@include font-size(20);

		line-height: 1.6;
		overflow: hidden;
		text-transform: none !important;

		strong {
			color: $red;
		}
	}

	.address-cards {
		display: flex;
		flex-wrap: wrap;

		@include breakpoint($min-lg) {
			margin: 0 -16px;
		}
	}

	.address-card {
		width: 100%;

		flex: 0 0 100%;
		display: flex;
		flex-direction: column;
		padding-bottom: 24px;

		@include breakpoint($min-sm) {
			width: 50%;
			flex: 0 0 50%;

			padding: 0 16px 48px;
		}

		@include breakpoint($min-lg) {
			width: 33.33%;
			flex: 0 0 33.33%;
		}

		.edit {
			margin-top: 20px;

			&:hover {
				background-color: $red;
			}
		}

		aside {
			margin-bottom: 1em;
		}

		form {
			border-bottom: 1px solid $tan-dark;
			margin-top: auto;
			margin-bottom: 0;
			padding-bottom: 24px;

			@include breakpoint($min-sm) {
				border-top: 1px solid $tan-dark;
				border-bottom: 0;
				padding-bottom: 0;
				padding-top: 1em;
			}

			input {
				margin: 0 5px;
			}
		}
	}

	// Edit

	.address-edit-primary {
		margin: 1em 0;
	}

	.address-edit-footer {
		margin: 2em -5px 0;

		input {
			margin: 0 5px;
		}
	}


	/* Shipping */

	.shipping-confirm {
		background-color: $red;

		&:hover {
			background-color: $black;
		}
	}

	.shipping-options {
		@include breakpoint($max-lg) {
			margin-top: 40px;	
		}
	}


	/* Payment */

	.txt-red {
		color: $red;
	}

	.order-review {
		margin-bottom: 40px;

		table {
			width: 100%;

			tr {

				td {
					@include font-size(20);

					border-bottom: 2px solid $tan-base;
					padding: 16px 0 10px;

					&:first-child {
						padding-right: 16px;
					}

					@include breakpoint($max-md) {
						@include font-size(18);
					}

					@include breakpoint($max-sm) {
						@include font-size(16);
					}
				}

				&:last-child {
					td {
						border-bottom-color: $gray-darkish;

						strong {
							font-weight: 800 !important;
						}
					}
				}
			}
		}
	}

	#gateway {
		label, legend {
			@include verlag-bold;
			@include font-size(14);

			color: $gray-darkish;
			display: block;
			font-weight: bold;
			margin-bottom: 15px;
			text-transform: uppercase;
		}
	}

	.payment-forms {
		margin-top: 30px;

		label, legend {
			@include verlag-bold;
			@include font-size(14);

			color: $gray-darkish;
			display: block;
			font-weight: bold;
			margin-bottom: 15px;
			text-transform: uppercase;
		}

		input.text {
			margin-bottom: 15px;
		}
	}

	.pay-button {
		background-color: $red;
		float: right;
		margin-top: 40px;

		&:hover {
			background-color: $black;
		}
	}

	.card-expiry-dropdown {
		width: 40%;
		display: inline-block;
	}

	.slash {
		@include verlag-black;
		@include font-size(24);

		display: block;
		line-height: 50px;
		text-align: center;
	}

	.card-data.StripeElement {
		@include font-size(20);

		background-color: $white;
		border: 1px solid transparentize($black, 0.75);
		padding: 12px;
	}

	.payment-stripe {

		.stripe-button-el {

			@include button;

			border: none;
			border-radius: 0;
			box-shadow: none;

			span {
				@include verlag-condensed-black;
				@include font-size(20);
				
				height: 50px;
				
				// background: $red;
				border: none;
				border-radius: 0;
				line-height: 50px;
				text-transform: uppercase;
			}

		}
	}


	/*	Customer Pages
	//------------------------------*/

	/* Customer Template */

	.customer-orders {
		table {
			tr {
				&:first-child {
					td {
						font-weight: 700;
					}
				}
			}
		}

		.cart-actions {
			margin-top: 0;
		}
	}

	.template-cart {
		.hidden {
			display: none;
		}

		fieldset {
			margin-bottom: 16px;
		}
	}

	.customer-addresses {
		margin-top: 40px;
		padding-bottom: 40px;
	}


	/* Order Template */

	.customer-order {

	}

	.customer-order-header {
		margin-bottom: 40px;

		h5 {
			margin-bottom: 16px;
		}
	}

	.customer-order-buttons {
		margin-top: 30px;
	}

	.all-orders {
		float: right;

		&:hover {
			background-color: $red;
		}
	}
