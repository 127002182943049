/*------------------------------>
	Components > Cards
<------------------------------*/

	.cards {
		padding-top: 30px;
		overflow: hidden;

		@include breakpoint($min-md) {
			padding-top: 40px;
		}

		@include breakpoint($min-lg) {
			padding-top: 60px;
		}
	}

	.cards-block-section {
		margin: 0 0 50px;

		@include breakpoint($max-sm) {
			margin-bottom: 0px;
		}
	}

	.cards-header {
		
	}

	.cards-heading {
		position: relative;

		text-align: center;

		@include breakpoint($max-lg) {
			padding: 0 50px;
		}
	}

	.cards-content {
		padding-top: 20px;

		@include breakpoint($max-sm) {
			padding-top: 0;
		}
	}

	.cards-carousel {
		&.fs-carousel.fs-carousel-enabled.fs-light {
			.fs-carousel-pagination {
				margin: 20px 0 0;
			}

			.fs-carousel-page {
				background-color: $red;
			}
		}
	}


	/*	Card
	//------------------------------*/

	.cards-carousel-item {

		@include breakpoint($max-md) {

			.fs-grid .fs-row & {
				margin: 0;

				padding-right: 10px;
				padding-left: 10px;
			}
		}
	}

	.card {
		position: relative;
		z-index: 1;

		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-color: $gray-dark;
		box-shadow: 0 11px 20px rgba(0,0,0,0.15);
		transition: transform 0.25s ease-out;

		&:after {
			@include full-width;
			@include gradient-vertical(transparentize($black-gradient, 1), $black-gradient);

			height: 70%;

			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -1;

			content: "";
			display: block;
		}

		&.card-hover {
			&:hover {
				@include breakpoint($min-md) {
					transform: scale(1.03);
				}
			}
		}
		
	}

	.card-container,
	.card-link {
		border-top: 5px solid $red-bright;
		display: block;
		padding-top: 133.33%;

		@include breakpoint($max-sm) {
			padding-top: 100%;
		}

		@include breakpoint($sm-md) {
			@include breakpoint(max-height 500px) {

				padding-top: 100%;
			}
		}

		@include breakpoint($min-lg) {
			@include breakpoint($max-ht) {
				padding-top: 60vh;
			}
		}
	}

	.card-content {
		position: absolute;
		bottom: 0;
		z-index: 1;

		padding: 30px;
	}

	.card-date {
		background-color: $red;
		margin-bottom: 15px;
	}

	.card-price-container {
		position: absolute;
		top: 30px;
		left: 0;
	}

	.card-price {
		background-color: $red;
		padding: 8px 12px;

		&.price-disabled {
			background-color: $gray-light;
			color: $gray-dark;
			text-decoration: line-through;

			margin-bottom: 10px;
		}
	}

	.card-title {
		@include verlag-condensed-black;
		@include font-size(26);
		@include font-smoothing;

		color: $white;
		line-height: 1.1;
		text-transform: uppercase;
	}

