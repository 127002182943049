/*------------------------------>
	Components > Photo Collage
<------------------------------*/

	.photo-collage {
		position: relative;

		// background-color: $white;
		// border-bottom: 2px solid $tan-border;
		// border-top: 2px solid $tan-border;
		background-position: center center;
		background-size: cover;
		margin-top: 80px;
		margin-bottom: 40px;

		@include breakpoint($max-sm) {
			margin-top: 40px;
			margin-bottom: 0;
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 1;

			background-color: transparentize($gray-dark, 0.5);
			content: "";
		}
	}

	.photo-collage-container {
		// @include bg-lines-vertical;
		@include clearfix;

		position: relative;
		z-index: 2;

		// border-bottom: 2px solid $tan-border;
		// border-top: 2px solid $tan-border;
		overflow: hidden;
		padding: 115px 0;

		@include breakpoint($max-sm) {
			padding: 40px 0;
		}
	}


	/*	Plate
	//------------------------------*/

	.photo-collage-plate {
		@include bg-tan-light;
		@include box-shadow;

		position: relative;

		background-color: $off-white;
		padding: 55px 50px;
		text-align: center;

		&:before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			border: 10px solid $teal;
			content: "";
			pointer-events: none;
		}

		@include breakpoint($max-sm) {
			padding: 45px 30px;
		}
	}


	/*	Header */

	.photo-collage-header {
		&:after {
			@include icon-divider;

			margin: 20px auto;
			content: "";
			display: block;
		}
	}

	.photo-collage-heading {
		@include verlag-condensed-black;
		@include font-size(32);

		color: $red;
		text-transform: uppercase;

		@include breakpoint($min-sm) {
			@include font-size(42);
		}

		@include breakpoint($min-lg) {
			@include font-size(56);
		}

		@include breakpoint($min-xl) {
			@include font-size(76);
		}
	}

	.photo-collage-text {

		p {
			@include breakpoint($min-lg) {
				@include font-size(22);	
			}
		}
	}

	.photo-collage-footer {
		margin-top: 30px;
	}

	.photo-collage-button {
		background-color: $red;

		&:hover {
			background-color: $black;
		}

		@include breakpoint($max-sm) {
			@include font-size(18);
		}
	}


	/*	Photos
	//------------------------------*/

	.photo-collage-photos {
		@include full;

		position: absolute;
		top: 50%;
		left: 0;
		z-index: -1;

		@include breakpoint($max-sm) {
			display: none;
		}
	}

	.photo-collage-photos-container {
		position: relative;
		z-index: -1;
	}

	.photo-collage-photo {
		@include box-shadow;

		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;

		// transform: translate(-50%, -50%);

		.photo-collage-photo-image {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			background-size: cover;
		}

		&.tl {
			@include size(590, 400);
			
			z-index: 2;

			margin-left: -900px;
			margin-top: -450px;
		}

		&.tr {
			@include size(550, 360);
			
			z-index: 2;

			margin-left: 40px;
			margin-top: -400px;

			&:after {
				@include full;

				position: absolute;
				bottom: -20px;
				right: -20px;
				z-index: -1;

				background-color: $green;
				content: "";
				display: block;
			}
		}

		&.bl {
			@include size(520, 340);
			
			z-index: 1;

			margin-left: -800px;
			margin-top: 40px;

			&:after {
				@include full;

				position: absolute;
				top: -20px;
				left: -20px;
				z-index: -1;

				background-color: $yellow;
				content: "";
				display: block;
			}
		}

		&.br {
			@include size(740, 500);
			
			z-index: 1;

			margin-left: 150px;
			margin-top: -150px;
		}

		&.bg {
			@include size(1440, 640);

			z-index: 0;

			margin-left: 200px;
			transform: translate(-50%, -50%);

			.photo-collage-photo-image {

				&:before {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					background-color: $teal;
					content: "";
					opacity: 0.8;
				}
			}
		}

		&.tbg {
			@include size(800, 600);

			background-color: $red;

			z-index: -1;

			margin-left: -1000px;
			margin-top: -360px;

			&:before {
				@include bg-texture-dark;

				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				content: "";
			}
		}
	}
