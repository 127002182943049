/*------------------------------>
	Templates > Brewery
<------------------------------*/

	/*	Brewery Intro
	//------------------------------*/

	.brewery-intro {
		position: relative;
		z-index: 1;

		padding-bottom: 30px;

		@include breakpoint($min-md) {
			padding-bottom: 40px;
		}

		@include breakpoint($min-lg) {
			padding-bottom: 60px;
		}

		+ .vendor-cards {
			padding-top: 0;
		}
	}

	.brewery-intro-header {
		@include clearfix();

		padding-bottom: 60px;

		&:after {
			@include full-width;
			@include bg-lines-vertical;

			width: 200%;
			height: 10px;

			position: absolute;
			left: -50%;
			z-index: -1;
			// bottom: -20px;

			content: "";
			margin-top: 20px;
		}

		@include breakpoint($max-md) {
			text-align: center;
		}
	}

	.brewery-intro-heading {
		@include page-heading;
		@include font-size(40);

		@include breakpoint($max-md) {
			margin-top: 20px;
		}
		@include breakpoint($min-md) {
			@include font-size(52);

			margin-bottom: 10px;
		}
		@include breakpoint($min-lg) {
			@include font-size(66);	
		}
	}

	.brewery-intro-content {
		p, a {
			@include font-size(18);
		}

		@include breakpoint($min-md) {
			padding-right: 40px;

			p, a {
				@include font-size(20);
			}
		}
	}

	.brewery-intro-image-wrapper {
		padding-top: 30px;

		@include breakpoint($min-md) {
			padding-top: 40px;
		}

		@include breakpoint($min-lg) {
			padding-top: 0;
		}
	}

	.brewery-intro-image {
		position: relative;
		z-index: 1;
		
		background-color: $gray-dark;
		background-position: center center;
		background-size: cover;

		&:before,
		&:after {
			position: absolute;

			content: "";
			display: block;
		}

		&:before {
			top: 7px;
			left: 7px;
			bottom: 7px;
			right: 7px;
			z-index: -1;

			border: 3px solid #555;
		}

		// &:after {
		// 	@include full-width;
		// 	@include gradient-vertical(rgba(38, 38, 38, 0), rgba(38, 38, 38, 1));

		// 	height: 50%;

		// 	bottom: 0;
		// 	left: 0;
		// 	z-index: -2;
		// }
	}

	.brewery-intro-image-container {
		position: relative;
		
		display: block;
		padding-top: 100%;

		@include breakpoint($sm-lg) {
			padding-top: 66.67%;
		}

		@include breakpoint($min-lg) {
			@include breakpoint($max-ht) {
				padding-top: 70%;
			}
		}
	}

	.vendor-cards {

		.cards-heading {
			@include breakpoint($max-sm) {
				@include font-size(28);
			}
		}

		.block-section {
			margin-bottom: 35px;
		}
	}


	/*	Team Cards
	//------------------------------*/

	.team-cards {
		background-color: $gray-dark;
		border-top: 30px solid $gray-light;
		margin-top: 30px;
		margin-bottom: -60px;
		padding-bottom: 60px;

		@include breakpoint($min-lg) {
			padding-top: 30px;
		}

		.team-cards-section {
			&:before {
				@include bg-lines-diagonal-gray;
			}

			margin-bottom: 0;
		}

		.card.team {
			&:after {
				content: none;
			}

			@include breakpoint($min-md) {
				&:nth-child(3n+1) {
					clear: left;
				}
			}
		}

		.team-cards-header {
			color: $gray-light;

			&:before {
				background-color: $gray-banner;
			}

			&:after {
				@include bg-lines-diagonal-gray;
			}
		}

		.team-cards-heading {
			@include verlag-condensed-black;
			@include font-smoothing;

			color: $off-white;
			padding: 0;
			text-shadow: -5px 5px 0 $black;

			@include breakpoint($max-sm) {
				
			}
		}

		.card-link {
			border-top-color: $red-bright;
			padding-top: 115%;

			@include breakpoint($min-lg) {
				@include breakpoint($max-ht) {
					padding-top: 100%;
				}
			}
		}

		.card-content {
			position: relative;

			text-align: center;

			@include breakpoint($max-sm) {
				padding-bottom: 0;
			}
		}

		.card-name {
			@include heading-4;
			@include font-smoothing;

			color: $off-white;
			line-height: 1.1;
			margin-bottom: 10px;

			@include breakpoint($max-lg) {
				@include font-size(28);
			}

			@include breakpoint($max-sm) {
				@include font-size(26);

				margin-bottom: 5px;
			}
		}

		.card-title {
			@include verlag-black;
			@include font-size(14);
			@include font-smoothing;

			color: $gray-light;			
			text-transform: uppercase;
		}

		@include breakpoint($max-sm) {
			margin-top: 0;
		}

		.cards-carousel-item {

			@include breakpoint($md-lg) {
				&:nth-child(3n+1) {
					clear: both;
				}
			}

			@include breakpoint($min-lg) {
				&:nth-child(4n+1) {
					clear: both;
				}
			}
		}
	}


	/*	Team Bio Pages
	//------------------------------*/

	.team-member {
		position: relative;
		z-index: 1;
	}

	.team-member-image-wrapper {
		padding-bottom: 30px;

		@include breakpoint($min-md) {
			width: 50%;
			padding-bottom: 50px;
			margin-right: 50px;
			// margin-left: -20%;

			float: left;
		}

		@include breakpoint($min-lg) {
			width: 70%;

			margin-left: -15%;
		}

		@include breakpoint($min-xl) {
			margin-left: -25%;
		}
	}

	.team-member-image {
		position: relative;
		z-index: 1;
		
		background-color: $gray-dark;
		background-size: cover;

		&:before,
		&:after {
			position: absolute;

			content: "";
			display: block;
		}

		&:before {
			top: 7px;
			left: 7px;
			bottom: 7px;
			right: 7px;
			z-index: -1;

			border: 3px solid #555;
		}
	}

	.team-member-image-container {
		position: relative;
		
		display: block;
		padding-top: 100%;
	}

	.team-member-content {

		@include breakpoint($min-md) {
			padding-left: 30px;
		}

		.entry-description {
			margin-bottom: 40px;

			p, ul li, ol li, a {
				@include font-size(18);

				@include breakpoint($min-lg) {
					@include font-size(20);
				}
			}
		}
	}

	.team-member-header {
		padding-bottom: 60px;

		&:after {
			@include full-width;
			@include bg-lines-vertical;

			width: 200%;
			height: 10px;

			position: absolute;
			left: -50%;
			z-index: -1;
			// bottom: -20px;

			content: "";
			margin-top: 20px;
		}
	}

	.team-member-heading {
		@include page-heading;

		color: $gray-light;
		line-height: 1;
		margin-bottom: 15px;
	}

	.team-member-title {
		@include verlag-black;
		@include font-size(20);

		color: $gray-dark;

		text-transform: uppercase;
	}
