/*------------------------------>
	Document > Buttons
<------------------------------*/

	/*	Mixins
	//------------------------------*/

	@mixin button {
		@include transition-link;

		background: transparent;
		border: none;
		outline: none;
		padding: 0;
		-webkit-appearance: none;
	}
	
	@mixin button-block {
		@include button;
		@include verlag-condensed-black;
		@include font-smoothing;

		background: $gray-dark;
		display: inline-block;
		color: $white;
		line-height: 1;
		text-transform: uppercase;
	}

	@mixin button-block-xlarge {
		@include button-block;
		@include font-size(38);

		padding: 20px 30px;
	}

	@mixin button-block-large {
		@include button-block;
		@include font-size(24);

		padding: 14px 20px;

		@include breakpoint($max-md) {
			@include font-size(20);

			padding: 13px 16px;
		}
	}

	@mixin button-block-medium {
		@include button-block;
		@include font-size(14);

		padding: 13px 16px;
	}

	@mixin button-block-small {
		@include button-block;
		@include verlag-black;
		@include font-size(12);

		padding: 8px;
	}

	/*	Selectors
	//------------------------------*/

	.button {
		@include button;
	}

	.button-block-xl {
		@include button-block-xlarge;

		@include breakpoint($max-md) {
			@include button-block-large;
		}
	}

	.button-block-lg {
		@include button-block-large;
	}

	.button-block-md {
		@include button-block-medium;
	}

	.button-block-sm {
		@include button-block-small;
	}

	.button-primary {
		background-color: $red;
	}
