/*------------------------------>
	Templates > Home
<------------------------------*/

	/*	Page Header
	//------------------------------*/

	.page-header-home {
		min-height: 400px;

		@include breakpoint($min-sm) {
			min-height: 480px;
		}

		@include breakpoint($min-md) {
			min-height: 550px;
		}

		@include breakpoint($min-lg) {
			min-height: 700px;
		}

		@include breakpoint($min-xl) {
			min-height: 900px;
		}

		@include breakpoint($min-lg) {
			@include breakpoint($max-ht) {
				min-height: 660px;
			}
		}

		.page-header-content {
			@include full-width;

			@include breakpoint($max-sm) {

				.fs-row,
				.fs-cell {
					width: 100%;
					margin: 0;
				}
			}
		}

		.page-header-container {
			@include breakpoint($max-sm) {
				padding: 25px 25px 0;
			}

			@include breakpoint($min-sm) {
				padding-top: 3em;	
			}
		}
	}

	.page-header-home-buttons {
		width: 100%;

		position: relative;
		z-index: 1;

		margin-top: 25px;

		text-align: center;

		> a {
			@include box-shadow;

			margin-bottom: 10px;
		}

		@include breakpoint($min-sm) {
			margin-top: 40px;
		}
	}


	.home-cards {
		overflow: hidden;
	}