/*------------------------------>
	Document > Variables
<------------------------------*/

	$base-font-size: 16;


	/*	Colors
	//------------------------------*/

	$white: #FFFFFF;
	$black: #363636;
	$blacker: #303030;
	$black-gradient: #242424;
	$true-black: #000000;

	$off-white: #f6f7f0;

	$gray-dark: #404041;
	$gray-banner: #4c4c4c;
	$gray-pale: #f1f1f1;
	$gray-border: #bcbcbc;
	$gray-light: #a8a49b;
	$gray-middle: #898989;
	$gray-darkish: #66666C;
	$gray-fringe: #b0aca5;

	$tan-pale: #f3eee2;
	$tan-base: #DDD2BE;
	$tan-dark: #CFC2A8;
	$tan-border: #E1DBCA;

	$brown: #605a51;

	$red: #DC4234;
	$red-dull: #c2413b;
	$red-bright: #eb2726;

	$orange: #D24D24;

	$gold: #b28c28;

	$blue: #36adbf;
	$yellow: #fce104;
	$green: #08723c;
	$teal: #36ADBF;


	/*	Transition Speed
	//------------------------------*/

	$default-speed: 0.15s;
	$default-delay: 0s;

	/*	Dimensions
	//------------------------------*/

	/* Icon Sprite */
	$sprite-width:  500px;
	$sprite-height: 350px;

	/* WordPress Admin Bar */
	$wpadminbar-ht-mobile:  46px;
	$wpadminbar-ht-desktop: 32px;

	/* iPad */
	$ipad-ht: 1024px;
	$ipad-wt: 768px;

	/* Header */
	$header-ht-mobile:  0px;
	$header-ht-desktop: 0px;

	/* Aspect Ratios */
	$sixteen-nine: 0.5625;
	$golden-ratio: 0.618;
	$three-two:    0.6667;
	$standard-def: 0.75;

	/* Aspect Ratio Percentages */
	$sixteen-nine-percent: 56.25%;
	$golden-ratio-percent: 61.8%;
	$standard-def-percent: 75%;
	$three-two-percent:    66.667%;


	/*	Grid Breakpoints
	//------------------------------*/

	$only-screen: 'only screen';

	$sm: 500;
	$md: 740;
	$lg: 980;
	$xl: 1220;

	$ht: 780px;

	$min-sm: $only-screen ($sm * 1px);
	$min-md: $only-screen ($md * 1px);
	$min-lg: $only-screen ($lg * 1px);
	$min-xl: $only-screen ($xl * 1px);

	$max-sm: $only-screen 0px ($sm - 1px);
	$max-md: $only-screen 0px ($md - 1px);
	$max-lg: $only-screen 0px ($lg - 1px);
	$max-xl: $only-screen 0px ($xl - 1px);

	$sm-md: $only-screen ($sm * 1px) ($md - 1px);
	$sm-lg: $only-screen ($sm * 1px) ($lg - 1px);
	$sm-xl: $only-screen ($sm * 1px) ($xl - 1px);

	$md-lg: $only-screen ($md * 1px) ($lg - 1px);
	$md-xl: $only-screen ($md * 1px) ($xl - 1px);

	$lg-xl: $only-screen ($lg * 1px) ($xl - 1px);

	$max-ht: $only-screen ($lg * 1px) (max-height $ht);
