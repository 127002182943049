/*------------------------------>
	Templates > Beer
<------------------------------*/

	.beer-badges {
		position: absolute;
		bottom: 80px;
		left: 0;
		right: 0;

		margin: 0 auto;
	}

	.beer-badge {
		@include verlag-black;
		@include font-size(11);
		@include size(80);
		@include font-smoothing;

		position: relative;
		z-index: 1;

		background-color: $black;
		border-radius: 100%;
		color: $white;
		display: block;
		margin-bottom: 10px;
		text-transform: uppercase;
		transition: transform 0.1s ease-out;

		span {
			width: 100%;

			position: absolute;
			top: 50%;
			left: 0;

			display: block;
			padding: 10px;
			text-align: center;
			transform: translateY(-50%);
		}

		&.status {
			@include font-size(15);
		}

		@include breakpoint($max-md) {
			@include size(40);

			&.status {
				@include font-size(9);
			}
		}
	}


	/*	Beer Listing Template
	//------------------------------*/

	.beer-category-heading {
		text-align: center;
	}

	.beers {
		padding: 0 0 20px;
		text-align: center;

		@include breakpoint($min-md) {
			padding: 0 0 30px;
		}

		@include breakpoint($min-lg) {
			padding: 20px 0 50px;
		}

		.fs-row {
			@include font-size(0);
		}

		.fs-grid & .fs-row .fs-cell {
			float: none;
			display: inline-block;
			vertical-align: top;
		}

		@include breakpoint($max-sm) {
			padding: 0;
		}
	}


	.beer {
		position: relative;

		margin-bottom: 30px;
		text-align: center;

		@include breakpoint($min-md) {
			margin-bottom: 50px;
		}

		@include breakpoint($min-lg) {
			margin-bottom: 60px;
		}

		@include breakpoint($min-xl) {
			&:nth-child(4n+1) {
				clear: left;
			}
		}

		@include breakpoint($min-md) {
			&:hover {

				.beer-badge,
				.label-bar {
					transform: translate(0,10px);
				}
			
				.beer-image {
					transform: scale(1.075);
				}

				.beer-title {
					transform: translateY(10px);
				}
			}
		}

		.label-bar {
			margin-top: 10px;
			transition: transform 0.1s ease-out;

			@include breakpoint($max-sm) {
				transform: scale(0.6);
			}
		}
	}

	.beer-image {
		position: relative;
		margin: 0 auto;
		transition: transform 0.1s ease-out;

		img {
			width: auto;

			position: absolute;
			bottom: 0;
			left: 50%;
			
			transform: translateX(-50%);	
		}

		@include breakpoint($max-sm) {
			max-height: 50vh;
			width: 100px;
			height: 200px;

			img {
				max-height: 200px;
			}
		}

		@include breakpoint($min-sm) {
			width: 180px;
			height: 360px;

			img {
				max-height: 360px;
			}
		}

		@include breakpoint($min-md) {
			width: 200px;
			height: 380px;
		}

		@include breakpoint($min-lg) {
			width: 240px;
			height: 400px;

			img {
				max-height: 400px;
			}
		}

		@include breakpoint($min-xl) {
			height: 440px;
			
			img {
				max-height: 440px;
			}
		}
	}

	.beer-content {
		padding-top: 20px;

		@include breakpoint($min-md) {
			padding-top: 30px;
		}
	}

	.beer-title {
		@include heading-3;

		transition: transform 0.1s ease-out;

		@include breakpoint($md-lg) {
			@include font-size(30);
		}

		@include breakpoint($sm-md) {
			@include font-size(28);
		}

		@include breakpoint($max-sm) {
			@include font-size(20);
		}

		.beer-retired & {
			@include breakpoint($min-lg) {
				@include font-size(24);
			}
		}
	}


	/*	Beer Archive Template
	//------------------------------*/

	.beer-archive-table {
		border-collapse: separate;

		@include breakpoint($max-sm) {
			
			table,
			thead,
			tbody,
			tr, td {
				display: block;
			}

			th {
				display: inline-block;
			}

			tr {
				border-bottom: 2px solid $gray-light;
				text-align: center;
			}

			td {
				border: none !important;
				text-align: center;

				&:nth-child(1) {
					@include font-size(22);

					padding-bottom: 0;
				}

				&:nth-child(2) {
					@include verlag-black;
					@include font-size(14);

					color: $red;
				}

				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5) {
					display: inline-block;

					padding: 0 14px 14px;
				}

				&:nth-child(3):before {
					content: "ABV: "
				}

				&:nth-child(4):before {
					content: "IBU: "
				}
			}
		}
	}

	.button-retired {
		background-color: $tan-dark;
	}


	.button-active {
		background-color: $red;
	}


	/*	Beer Product Template
	//------------------------------*/

	.template-beer-product {

		.block-arrow {
			position: absolute;
			top: 50%;
			margin-top: -20px;

			@include breakpoint($md-lg) {
				top: 20%;
			}

			@include breakpoint($max-md) {
				top: 15%;
			}
		}

		.arrow-left {
			left: 0;
		}

		.arrow-right {
			right: 0;
		}
	}

	.beer-product {
		position: relative;
		z-index: 1;

		padding-bottom: 40px;

		.beer-badges {
			top: 10px;
			left: 10px;
			right: auto;

			.beer-badge {

			}
		}
	}

	.beer-product-image-container {
		position: relative;

		text-align: center;

		@include breakpoint($min-lg) {
			position: sticky;
			top: 0;
		}
	}

	.beer-product-image {
		
		@include breakpoint($max-md) {
			
			img {
				width: auto;
				max-width: 50vw;
			}
		}

		@include breakpoint($max-sm) {

			img {
				max-height: 50vh;
			}
		}

		@include breakpoint($min-sm) {
			img {
				width: auto;
				max-height: 360px;
			}
		}

		@include breakpoint($min-md) {
			img {
				max-height: 480px;
			}
		}

		@include breakpoint($min-lg) {
			margin-right: -100px;
		}

		@include breakpoint($min-xl) {
			margin-right: -120px;

			img {
				max-height: 700px;
			}
		}
	}

	.beer-product-header {
		@include clearfix();

		padding-bottom: 60px;

		&:after {
			@include full-width;
			@include bg-lines-vertical;

			width: 200%;
			height: 10px;

			position: absolute;
			left: -50%;
			z-index: -1;
			// bottom: -20px;

			content: "";
			margin-top: 20px;
		}

		@include breakpoint($max-md) {
			text-align: center;
		}
	}

	.beer-product-heading {
		@include page-heading;

		@include breakpoint($max-md) {
			margin-top: 20px;
		}

		@include breakpoint($min-md) {
			margin-bottom: 10px;
		}
	}

	.beer-product-style {

		a {
			@include verlag-black;
			@include font-size(20);

			color: $black;
			text-transform: uppercase;

			&:hover {
				color: $blacker;
			}
		}

		@include breakpoint($min-md) {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
		}
	}

	.beer-product-categories {
		a {
			@include verlag-black;
			@include font-size(16);

			color: $red;
			text-transform: uppercase;

			&:hover {
				color: $black;
			}

			@include breakpoint($max-md) {
				margin-top: 10px;
				margin-right: 5px;
				margin-left: 5px;
			}

			@include breakpoint($min-md) {
				margin-right: 5px;
			}
		}

		@include breakpoint($min-md) {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
		}
	}

	.beer-product-labels {
		margin-top: 10px;

		.label-bar {

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.beer-product-description {
		p, a, ul li, ol li {
			@include font-size(18);
		}

		a {
			background-size: 3px .3em;
		}
	}

	.beer-product-tables {
		margin-top: 40px;

		@include breakpoint($md-lg) {
			clear: both;
		}
	}

	table.beer-product-vitals-table,
	table.beer-product-availability-table,
	table.beer-product-ingredients-table {
		width: 100%;

		tbody tr {
			td {
				color: $red;
			}

			&:hover {
				td {
					background-color: transparent;
				}
			}
		}
	}

	.beer-product-vitals-blocks {
		@include verlag-condensed-black;

		display: flex;
		border-left: 2px solid $black;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		text-transform: uppercase;

		@include breakpoint($min-md) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	.beer-product-vitals-block {
		border-right: 2px solid $black;
		display: flex;
		justify-content: center;

		@include breakpoint($max-md) {
			flex: 1 0 50%;

			&.pairing, &.to-go-options {
				flex: 1 0 100%;
			}
		}

		@include breakpoint($min-md) {
			flex: 1;

			&.abv, &.local {
				flex: 0;
			}
		}

		> div {
			width: 100%;
			display: flex;
			flex-direction: column;

			> div {
				padding: 12px 20px;
				
				@include breakpoint($min-lg) {
					padding: 12px 24px;
				}
			}
		}
	}

	.beer-product-vitals-block-label {
		@include verlag-black;
		@include font-size(14);
		@include font-smoothing;

		background-color: $black;
		color: $white;
		flex: 0 0 auto;
		text-align: center;
		white-space: nowrap;
	}

	.beer-product-vitals-block-content {
		@include font-size(24);

		align-items: center;
		color: $red;
		display: flex;
		flex: 1 0 auto;
		justify-content: center;
		text-align: center;

		&.abv,
		&.ibu,
		&.local {
			@include font-size(50);
		}
	}


	table.beer-product-vitals-table {
		margin-bottom: -2px;

		thead th {
			text-align: center;
		}

		tbody td {
			@include font-size(24);

			border-right: 2px solid $black;
			text-align: center;

			&.abv,
			&.ibu,
			&.local {
				@include font-size(50);
			}

			&:hover {
				background-color: $white;
			}
		}

		@include breakpoint($max-md) {
			display: block;

			tr {
				display: block;
			}

			thead th,
			tbody td {
				display: inline-block;
			}
		}
	}

	table.beer-product-availability-table {
		margin-bottom: -2px;

		thead th {
			text-align: center;
		}

		tbody td {
			@include font-size(24);

			border-right: 2px solid $black;
			text-align: left;

			&.availability {
				@include font-size(36);
			}

			&:hover {
				background-color: $white;
			}
		}

		
	}

	table.beer-product-ingredients-table {
		
		tbody tr {
			td {
				background-color: $white;
				padding: 15px 15px;

				&:first-child {
					@include verlag-black;
					@include font-size(14);
					@include font-smoothing;

					width: 86px;

					background-color: $black;
					border-color: $black;
					color: $white;
					padding: 10px 15px;
					text-align: center;
				}

				&:not(:first-child) {
					@include verlag;

					color: $gray-dark;
					text-transform: none;
				}
			}

			&:hover {
				td {
					background-color: $white;

					&:first-child {
						background-color: $black;
					}
				}
			}
		}
	}


	/* Beer Awards */

	.beer-product-awards {
		padding: 35px 0 20px;
	}

	.beer-product-awards-header {
		margin-bottom: 20px;
	}

	.beer-product-awards-heading {
		@include heading-4;

		text-align: center;
	}

	.beer-product-award {
		padding: 20px;
		text-align: center;

		@include breakpoint($min-sm) {
			width: 50%;

			float: left;
		}

		@include breakpoint($min-md) {
			width: 33.3%;
		}
	}

	.beer-product-award-image {
		max-width: 150px;
		margin: 0 auto 20px;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.beer-product-award-name {
		@include verlag-black;
		@include font-size(16);

		text-transform: uppercase;
	}

	.beer-product-award-description {
		@include verlag-italic;
		@include font-size(14);

		margin-top: 10px;
		text-transform: uppercase;
	}


	/* Beer Vendors Map */

	.vendors {
		background-color: $gray-dark;
		border-top: 30px solid $gray-light;
		padding: 80px 0 60px;

		.block-list-item {
			&:nth-child(odd) {
				background-color: $blacker;
			}

			.block-list-item-content {
				display: inline-block;
			}
		}
	}

	.vendors-section {
		
		&:before {
			@include bg-lines-diagonal-gray;
		}
	}

	.vendors-header {

		&:before {
			background-color: $gray-banner;
		}

		&:after {
			@include bg-lines-diagonal-gray;
		}
	}

	.vendors-list.block-list {
		counter-reset: vendors;

		@include breakpoint($min-lg) {
			transform: translateX(460px);
		}

		@include breakpoint($min-xl) {
			transform: translateX(565px);
		}
	}

	.vendors-list-item {
		counter-increment: vendor;
		padding-top: 25px;
		padding-bottom: 25px;

		&:before {
			@include size(46);
			@include verlag-black;
			@include font-size(28);
			@include font-smoothing;

			background-color: $red;
			border-radius: 100%;
			content: counter(vendor, upper-alpha);
			color: $white;
			display: inline-block;
			line-height: 46px;
			margin-right: 20px;
			text-align: center;
			vertical-align: top;
		}

		@include breakpoint($max-lg) {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			&:before {
				flex: 0 0 46px;
			}

			a {
				flex: 1;
			}
		}

		@include breakpoint($max-sm) {

			.vendors & .block-list-item-content {
				display: block;
				overflow: hidden;
			}

			&:before {
				float: left;
			}
		}
	}

	.vendors-heading {
		@include verlag-condensed-black;
		@include font-size(60);
		@include font-smoothing;

		color: $off-white;
		padding: 30px 0;
		text-shadow: -5px 5px 0 $black;
	}

	.vendors-title {
		@include font-smoothing;

		color: $off-white;
	}

	.vendors-info {
		@include verlag-black;
		@include font-size(14);
		@include font-smoothing;

		color: $gray-light;
		text-transform: uppercase;
	}

	.vendor-map {
		position: relative;
		z-index: 1;
		
		background-color: $gray-dark;
		background-size: cover;

		&:before,
		&:after {
			position: absolute;

			content: "";
			display: block;
		}

		&:before {
			top: 7px;
			left: 7px;
			bottom: 7px;
			right: 7px;
			z-index: 1;

			border: 3px solid #555;
		}

		&:after {
			@include full;

			bottom: 0;
			left: 0;
			z-index: 0;

			box-shadow: inset 0 0 40px 16px $tan-base;
		}
	}

	.vendor-map-container {
		position: relative;
		
		display: block;
		padding-top: 111%;
		overflow: hidden;

		.vendor-map-image {
			position: absolute;
			top: 0;
			z-index: -1;
		}

		img {
			height: auto;
		}
	}

	.vendors-footer.block-footer {
		@include breakpoint($min-lg) {
			transform: translateX(460px);
		}

		@include breakpoint($min-xl) {
			transform: translateX(565px);
		}

		.block-footer-content {
			&:after {
				border-top-color: $red;
				border-bottom-color: $red;
			}
			&:hover {
				background-color: $off-white;

				.vendors-link {
					color: $gray-dark;
				}

				&:after {
					border-top-color: $off-white;
					border-bottom-color: $off-white;
				}
			}
		}
	}

	.ingredient-images {
		margin-top: 30px;

		@include breakpoint($min-sm) {
			margin-top: 40px;
		}
		
		@include breakpoint($min-md) {
			margin-top: 55px;
		}

		@include breakpoint($min-lg) {
			margin-top: 85px;
		}
	}

	.ingredient-item {
		position: relative;

		margin-bottom: 30px;
		text-align: center;


		@include breakpoint($max-sm) {
			&:nth-child(2n+1) {
				clear: both;
			}
		}

		@include breakpoint($sm-md) {
			&:nth-child(3n+1) {
				clear: both;
			}
		}

		@include breakpoint($md-lg) {
			&:nth-child(4n+1) {
				clear: both;
			}
		}

		@include breakpoint($min-lg) {
			&:nth-child(5n+1) {
				clear: both;
			}
		}
	}

	.ingredient-counter {
		@include size(46);
		@include verlag-black;
		@include font-size(28);
		@include font-smoothing;

		position: absolute; 
		top: 10px;
		left: 10px;
		z-index: 1;

		background-color: $red;
		border-radius: 100%;
		color: $white;
		display: inline-block;
		line-height: 46px;
		margin-right: 20px;
		text-align: center;
		vertical-align: top;
	}

	.ingredient-image {
		position: relative;

		border-radius: 50%;
		box-shadow: 0 11px 20px 0 rgba(0,0,0,0.15);
		display: inline-block;
		overflow: hidden;

		&:before {
			width: 100%;
			height: 100%;

			position: absolute;
			top: 0;
			left: 0;

			border-radius: 50%;
			box-shadow: inset 0 0 50px 0 rgba(0,0,0,0.15);
			content: '';
			display: block;
		}

		img {
			width: 180px;
			height: auto;
			max-width: 100%;

			@include breakpoint($max-md) {
				width: 150px;
			}

			@include breakpoint($max-sm) {
				width: 120px;
			}
		}
	}

	.ingredient-title {
		@include verlag-condensed-black;
		@include font-size(20);
		@include font-smoothing;

		color: $white;
		margin-top: 16px;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(18);
		}
	}

	/* Related Beers */

	.related-beers {
		margin-top: 80px;

		.beer {

			@include breakpoint($min-sm) {
				padding: 0 20px;
			}

			@include breakpoint($min-md) {
				padding: 0 30px;	
			}

			@include breakpoint($min-lg) {
				padding: 0 40px;
			}

			.beer-title {
				@include font-size(24);
			}

			.beer-image {

				@include breakpoint($max-sm) {
					max-height: 50vh;
					width: 100px;
					height: 200px;

					img {
						max-height: 200px;
					}
				}

				@include breakpoint($min-sm) {
					width: 140px;
					height: 325px;

					img {
						max-height: 360px;
					}
				}

				@include breakpoint($min-md) {
					width: 165px;
					height: 360px;
				}

				@include breakpoint($min-lg) {
					width: 140px;
					height: 400px;

					img {
						max-height: 400px;
					}
				}

				@include breakpoint($min-xl) {
					width: 195px;
					height: 440px;
					
					img {
						max-height: 440px;
					}
				}
			}
		}
	}
