/*------------------------------>
	Templates > Tavern
<------------------------------*/

	/*	On Tap List
	//------------------------------*/

	.on-tap {
		.block-list {

		}
				
		.block-section-left .block-list {
			@include breakpoint($min-lg) {
				transform: translateX(540px);
			}

			@include breakpoint($min-xl) {
				transform: translateX(775px);
			}
		}
	}

	.on-tap-item {
		padding-top: 15px;
		padding-bottom: 15px;

		a {
			display: block;
		}

		@include breakpoint($max-sm) {
			
		}
	}

	.on-tap-item-info {
		width: 100%;
		display: inline-block;
    	vertical-align: middle;

    	@include breakpoint($min-lg) {
    		width: 530px;
    	}

    	@include breakpoint($min-xl) {
    		width: 765px;
    	}
	}

	.on-tap-item-heading {
		@include font-size(24);

		color: $red;

		@include breakpoint($min-sm) {
			@include font-size(20);	
		}

		@include breakpoint($min-md) {
			@include font-size(22);	
		}

		@include breakpoint($min-xl) {
			@include font-size(24);
		}

		.no-touchevents .on-tap-item:hover & {
			color: $gray-banner;
		}
	}

	.on-tap-style {
		@include verlag-bold;
		@include font-size(13);
		

		color: $gray-middle;
		text-transform: uppercase;

		@include breakpoint($min-md) {
			@include font-size(15);
		}

		@include breakpoint($min-lg) {
			@include font-size(16);
		}
	}

	.on-tap-table {
		width: 100%;

		@include breakpoint($min-sm) {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			grid-auto-rows: 1fr;
			grid-column-gap: 10px;
		}

		@include breakpoint($md-lg) {
			grid-column-gap: 20px;
			grid-template-columns: repeat(7, 1fr);
		}

		@include breakpoint($lg-xl) {
			grid-template-columns: repeat(6, 1fr);
		}

		@include breakpoint($min-xl) {
			grid-column-gap: 20px;
			grid-template-columns: repeat(7, 1fr);
		}
	}

	.on-tap-titles,
	.on-tap-item-stats {
		vertical-align: middle;

		> div {
			@include verlag-condensed-black;
			@include font-size(18);

			color: $gray-darkish;
			text-transform: uppercase;

			@include breakpoint($lg-xl) {
				@include font-size(18);
			}
		}

		&.to-go-prices {
			> div {
				@include font-size(13);
				line-height: 1.3;

				span {
					display: block;
					white-space: nowrap;
				}
			}
		}

		.no-touchevents .block-list-item:hover & > div {
			color: $gray-banner;
		}
	}

	.on-tap-titles {

		@include breakpoint($max-sm) {
			padding: 0 10px;
		}

		@include breakpoint($min-sm) {
			grid-column: 1 / span 2;
		}

		@include breakpoint($min-md) {
			grid-column: 1 / span 3;
		}

		@include breakpoint($lg-xl) {
			grid-column: 1 / span 2;
		}

		@include breakpoint($min-xl) {
			grid-column: 1 / span 3;
		}

		@include breakpoint($max-sm) {
			margin-bottom: 10px;
		}
	}

	.on-tap-stats {
		position: relative;

		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;

		@include breakpoint($min-sm) {
			grid-column: 3 / span 4;
		}

		@include breakpoint($min-md) {
			grid-column: 4 / span 4;
		}

		@include breakpoint($lg-xl) {
			grid-column: 3 / span 4;
		}

		@include breakpoint($min-xl) {
			grid-column: 4 / span 4;
		}
	}

	.on-tap-item-stats,
	.on-tap-item-offerings {
		height: 100%;

		position: relative;
		align-items: flex-start;
		display: flex;
		flex-direction: row;
		
		justify-content: center;
		padding: 0 10px;

		&:first-child {
			align-items: center;
		}

		@include breakpoint($md-lg) {
			padding: 0 20px;
		}

		@include breakpoint($min-xl) {
			padding: 0 20px;
		}

		&:before {
			width: 1px;

			position: absolute;
			top: -5px;
			bottom: -5px;
			left: 0;

			background-color: $black;
			content: "";
			display: block;
		}

		> div {
			// text-align: left;
		}

		.menu-item-abv {
			@include breakpoint($min-sm) {
				text-align: center;
			}
		}

		@include breakpoint($sm-md) {

			> div {
				// @include font-size(18);
			}
		}

		@include breakpoint(500px 600px) {
			> div {
				// @include font-size(15);
			}
		}

		@include breakpoint($max-sm) {
			> div {
				// @include font-size(15);
			}

			&:before {
				content: none;
			}
		}
	}

	.on-tap-item-stats {
		position: relative;

		flex: 0 1 30%;
		display: flex;
		align-items: center;

		@include breakpoint($max-sm) {
			justify-content: flex-start;
		}
	}

	.on-tap-item-offerings {
		flex: 0 1 40%;
		justify-content: flex-start;
		align-items: center;
		vertical-align: middle;
	}

	.on-tap-item-icon {
		margin: 0 3px;

		&.bottles {
			@include icon-bottle-12;
		}

		&.bottles-nonstandard {
			@include icon-bottle-plain;
		}

		&.cans {
			@include icon-can-12;
		}

		&.cans-sixteen {
			@include icon-can-16;
		}

		&.crowlers {
			@include icon-crowler;
		}

		&.growlers {
			@include icon-growler;
		}

		&.on-tap {
			@include icon-on-tap-pint;
			
			&.pint {
				@include icon-on-tap-pint;
			}

			&.tulip {
				@include icon-on-tap-tulip;
			}
		}
	}

	.nc-malt {
		width: 75px;
		height: 75px;

		position: absolute;
		right: 100%;

		text-indent: 75px;
		color: transparent !important;
		background: url(../images/site/ncmalt.png) no-repeat center;
		display: block;
		margin-right: 1rem;
		overflow: hidden;
	}


	/*	Tavern Info
	//------------------------------*/

	.template-boxyard,
	.template-tavern {

		.block-section {
			> .fs-row {
				> .fs-cell {
					&:last-child {
						@include breakpoint($min-lg) {
							position: sticky;
							top: 0;
							z-index: 1;
						}
					}
				}
			}
		}
	}

	.tavern-info {
		@include bg-texture-red;

		background-repeat: repeat;

		position: relative;
		z-index: 1;

		margin-top: 30px;
		margin-bottom: 30px;
		text-align: center;

		&.ticket-gray {
			@include bg-texture-black;
		}
	}

	.tavern-info-container {

	}

	.tavern-info-address {
		color: $white;

		&:hover {
			color: $black-gradient;
		}
	}

	/* Tavern Address */

	.tavern-address-header {
		
	}

	.tavern-address {
		@include verlag-condensed-black;
		@include font-size(24);
		@include font-smoothing;

		color: $off-white;
		text-transform: uppercase;

		@include breakpoint($md-lg) {
			@include font-size(24);

			display: table-cell;
			vertical-align: middle;
		}

		@include breakpoint($max-sm) {
			@include font-size(22);
		}
	}

	.tavern-phone {
		@include verlag-condensed-black;
		@include font-size(24);
		@include font-smoothing;

		color: $off-white;
		text-transform: uppercase;

		&:hover {
			color: $black-gradient;
		}

		@include breakpoint($md-lg) {
			@include font-size(24);
		}

		@include breakpoint($max-sm) {
			@include font-size(22);
		}
	}

	.tavern-info-content {
		@include breakpoint($md-lg) {
			display: table-cell;
			vertical-align: middle;
		}
	}

	/* Tavern Open */

	.tavern-open {
		background-color: $blacker;
		padding: 20px;
	}

	.tavern-open-heading {
		@include verlag-condensed-black;
		@include font-size(28);
		@include font-smoothing;

		color: $gray-light;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(24);
		}
	}

	.tavern-open-hours {
		@include verlag-condensed-black;
		@include font-size(36);
		@include font-smoothing;

		color: $off-white;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(28);
		}
	}

	.tavern-kitchen-open-heading {
		@include verlag-black;
		@include font-size(14);
		@include font-smoothing;

		color: $red;
		letter-spacing: 0.5px;
		margin-top: 10px;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(12);
		}
	}

	/* Tavern Hours */

	.tavern-hours {
		border: 1px solid $blacker;
		padding: 20px;

		> div {
			@include verlag-condensed-black;
			@include font-size(16);
			@include font-smoothing;

			color: $off-white;
			margin-bottom: 10px;
			text-transform: uppercase;
		}

		@include breakpoint($max-sm) {
			padding: 20px 10px;

			> div {
				@include font-size(12);
			}
		}

		@include breakpoint($sm-lg) {
			> div {
				@include font-size(14);
			}
		}
	}

	.tavern-hours-rows {

	}

	.tavern-hours-row {
		margin-bottom: 10px;
	}

	.tavern-hours-heading {
		@include verlag-condensed-black;
		@include font-size(32);
		@include font-smoothing;

		color: $off-white;
		margin-bottom: 15px;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(24);
		}

		@include breakpoint($sm-lg) {
			@include font-size(28);
		}
	}

	.tavern-hours-days {
		text-align: left;
	}

	.tavern-hours-hours {
		text-align: right;
	}

	/* Tavern Info Footer - Jump Links */

	.tavern-info-footer {
		background-color: $blacker;
		padding: 20px;

		> div {
			@include verlag-condensed-black;
			@include font-size(28);
			@include font-smoothing;

			color: $off-white;
			text-transform: uppercase;

			a {
				color: $red;
				margin: 0 5px;

				&:hover {
					color: $white;
				}
			}

			@include breakpoint($max-sm) {
				@include font-size(21);

				a {
					margin: 0 3px;
				}
			}

			@include breakpoint($md-lg) {
				@include font-size(24);
			}
		}
	}


	/*	Menu Callout
	//------------------------------*/

	.template-tavern .photo-collage {
		
		@include breakpoint($min-md) {
			margin-bottom: 55px;
		}

		@include breakpoint($min-xl) {
			margin-bottom: 80px;
		}
	}


	/*	Events
	//------------------------------*/

	.this-week {
		@include bg-texture-black;

		background-size: cover;

		@include breakpoint($md-lg) {
			margin-top: 30px;	
		}

		.event-featured-content {
			padding-left: 0;
			padding-top: 10px;
			padding-bottom: 10px;

			a {
				&:hover {
					.event-title {
						color: $red;
					}
				}
			}
		}

		.event-info {
			line-height: 1;
			text-align: left;
		}

		.event-title {
			@include font-size(18);
			@include font-smoothing;

			color: $white;
			line-height: 1.2;
			margin-bottom: 5px;
			text-align: left;
			transition: color 0.2s linear;
		}

		.event-list-item-info {
			border-left-width: 2px;
			border-left-color: #555;
			padding-top: 6px;
			padding-bottom: 10px;
		}

		.event-list-item-date {
			border-right-width: 2px;
			border-right-color: #555;
			margin-right: -2px;
			padding-top: 10px;
			padding-bottom: 10px;
		}

		.event-day {
			@include font-size(25);

			color: #A8A49C;
			line-height: 1;
		}

		.event-month {
			@include font-size(14);

			color: #A8A49C;
		}


	}

	.this-week-heading {
		@include font-size(50);

		@include breakpoint($max-md) {
			@include font-size(42);	
		}
	}

	.this-week-content-item {
		display: table;
	}

	.this-week-item-info,
	.this-week-item-date {
		display: table-cell;

		vertical-align: middle;
	}

	.this-week-item-info {

	}

	.this-week-item-link {
		display: block;
		margin-top: 15px;

		&:first-child {
			margin-top: 0;
		}
	}


	/*	To-Go
	//------------------------------*/

	.to-go {
		@include bg-texture-light;
	}

	.to-go-heading {
		@include heading-2;

		color: $blacker;
		line-height: 1;

		@include breakpoint($max-md) {
			@include font-size(42);	
		}
	}

	.to-go-content {
		@include verlag-condensed(normal, 700);
			
		strong {
			@include verlag-condensed-black;
		}

		&.ticket-fill {
			@include verlag-condensed-black;
			@include font-size(20);

			color: $white;
			text-align: center;
			padding-top: 8px;
			padding-bottom: 8px;

			@include breakpoint($min-md) {
				@include font-size(24);
			}
		}

		a {
			color: $blacker;

			&:hover {
				@include font-smoothing;

				color: $white;
			}
		}
	}

	
	/*	Food Menu
	//------------------------------*/

	.tavern-menu-heading {
		text-align: center;
	}

	.menu-item-category {
		margin-bottom: 30px;

		@include breakpoint($max-md) {
			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint($min-md) {
			&:nth-child(odd) {
				clear: left;
				float: left;
			}

			&:nth-child(even) {
				clear: right;
				float: right;
			}
		}
	}

	.menu-item-category-header {
		background: $black;
		padding: 15px 20px;

		@include breakpoint($max-sm) {
			padding: 10px 15px;
		}
	}

	.menu-item-category-heading {
		@include verlag-condensed-black;
		@include font-size(28);
		@include font-smoothing;

		color: $white;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(24);
		}
	}

	.menu-item-category-subheading {
		@include verlag-black;
		@include font-size(14);
		@include font-smoothing;

		color: $gray-light;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(12);
		}
	}

	.menu-item {
		padding: 20px 20px 25px;

		&:nth-child(odd) {
			background-color: $tan-pale;
		}
		&:nth-child(even) {
			background-color: $tan-base;
		}

		@include breakpoint($max-sm) {
			padding: 10px 15px 15px;
		}
	}

	.menu-item-header {
		
	}

	.menu-item-headings {
		
	}

	.menu-item-block {
		width: 100%;

		display: table;

		> * {
			display: table-cell;
			vertical-align: middle;
		}
	}

	.menu-item-heading {
		@include heading-4;
		@include font-smoothing;

		color: $gray-banner;
		padding-right: 10px;

		@include breakpoint($max-sm) {
			@include font-size(20);
		}

		@include breakpoint($min-sm) {
			@include font-size(24);
		}

		@include breakpoint($min-lg) {
			@include font-size(28);
		}
	}

	.menu-item-subheading {
		@include verlag-bold;
		@include font-size(14);

		color: $red;
		margin-top: 6px;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(12);
		}
	}

	.menu-item-add-on {
		@include verlag-italic;

		text-align: right;
	}

	.menu-item-cost {
		@include heading-4;

		color: $gray-banner;
		text-align: right;
		vertical-align: middle;

		@include breakpoint($max-sm) {
			@include font-size(28);
		}
	}

	.menu-item-content {
		margin-top: 20px;
		border-top: 1px solid $black;
		padding-top: 20px;

		@include breakpoint($max-sm) {
			margin-top: 10px;
			padding-top: 10px;
		}
	}

	.menu-item-description {
		clear: both;
	}

	.menu-item-pairing {
		a {
			color: $black;

			&:hover {
				color: $red;
			}
		}
	}


	/*	Locations (Visit)
	//------------------------------*/

	.locations {
		
	}

	.location-image {
		position: relative;
		z-index: 1;

		box-shadow: 0px 11px 20px 0px rgba(0, 0, 0, 0.15);
		overflow: hidden;
		padding-top: 100%;

		img {
			width: 100%;
			height: 100%;

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			object-fit: cover;
			object-position: center;
		}

		&:before {
			position: absolute;
			top: 7px;
			left: 7px;
			bottom: 7px;
			right: 7px;
			z-index: 1;

			border: 3px solid #555;
			content: '';
			display: block;
		}
	}

	.location-button {
		max-width: 360px;

		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		transform: translateY(50%);
		z-index: 2;

		background-color: $red;
		margin: 0 auto;
		padding: 0.75rem 1.25rem 0.5rem;
		text-align: center;

		@include breakpoint($min-xl) {
			max-width: 425px;
		}
	}

	.location-name {
		@include heading-3;

		@include breakpoint($min-lg) {
			@include heading-2;			
		}
	}

	.location-text {
		@include heading-5;
		@include verlag-black;
		@include font-smoothing;

		position: relative;
		z-index: 1;

		color: $white;
		margin-top: 0.5rem;

		span {
			display: inline-block;
			padding: 0 1.25rem;
		}

		&:before {
			width: 100%;
			height: 2px;

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;

			content: '';
			margin: auto;
		}
	}

	.location {
		position: relative;

		margin-bottom: 6rem;
		padding: 0 1rem;

		&:first-child {
			.location-button {
				background-color: $red;
				border: 3px solid $black;
				box-shadow: 0 0 0 5px $red;
			}

			.location-name {
				color: $black;
			}

			.location-text {
				span {
					background-color: $red;
				}

				&:before {
					background-color: $black;
				}
			}
		}

		&:last-child {
			.location-button {
				background-color: $black;
				border: 3px solid $gray-darkish;
				box-shadow: 0 0 0 5px $black;
			}

			.location-name {
				color: $red;
			}

			.location-text {
				span {
					background-color: $black;
				}

				&:before {
					background-color: $gray-darkish;
				}
			}
		}
	}

	.order-online {
		margin-bottom: 4.5rem;
		text-align: center;

		.page-intro + & {
			margin-top: -2.25rem;
		}
	}

	.order-online-button {

	}

	.order-online-button {
		max-width: 360px;

		background-color: $red;
		margin: 0 auto;
		padding: 0.75rem 1.25rem 0.5rem;
		text-align: center;

		@include breakpoint($min-xl) {
			max-width: 425px;
		}
	}

	.order-online-name {
		@include heading-3;

		@include breakpoint($min-lg) {
			@include heading-2;			
		}
	}

	.order-online-text {
		@include heading-5;
		@include verlag-black;
		@include font-smoothing;

		position: relative;
		z-index: 1;

		color: $white;
		margin-top: 0.5rem;

		span {
			display: inline-block;
			padding: 0 1.25rem;
		}

		&:before {
			width: 100%;
			height: 2px;

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;

			content: '';
			margin: auto;
		}
	}

	.order-online-button-container {

		&.red {
			.order-online-button {
				background-color: $red;
				border: 3px solid $black;
				box-shadow: 0 0 0 5px $red;
			}

			.order-online-name {
				color: $black;
			}

			.order-online-text {
				span {
					background-color: $red;
				}

				&:before {
					background-color: $black;
				}
			}
		}

		&.black {
			.order-online-button {
				background-color: $black;
				border: 3px solid $gray-darkish;
				box-shadow: 0 0 0 5px $black;
			}

			.order-online-name {
				color: $red;
			}

			.order-online-text {
				span {
					background-color: $black;
				}

				&:before {
					background-color: $gray-darkish;
				}
			}
		}
	}

	.kitchen-closes {
		font-size: 1rem;
		margin-top: 1.5rem;
	}