/*------------------------------>
	Document > Icons
<------------------------------*/

	/*	Logos
	//------------------------------*/

	@mixin header-badge {
		@include icon-sprite(0, -100px);
		@include size(78, 104);
	}

	@mixin header-logo {
		@include icon-sprite();
		@include size(250, 37);
	}

	@mixin footer-badge {
		@include icon-sprite(-80px, -100px);
		@include size(164, 172);
	}

	@mixin footer-logo {
		@include icon-sprite(0, -37px);
		@include size(350, 52);
	}

	@mixin footer-logo-background {
		@include size(970, 280);

		background: url(../images/site/watermark.png) no-repeat 0 0;

		.svg & {
			background-image: url(../images/site/watermark.svg);
		}
	}

	@mixin mobile-menu-logo-background {
		background: url(../images/site/watermark-red.png) no-repeat 0 0;

		.svg & {
			background-image: url(../images/site/watermark-red.svg);
		}
	}


	/*	Background Patterns
	//------------------------------*/

	@mixin bg-tan-light {
		background: url(../images/site/tan-light-noise-bg.png) repeat top left;
	}

	@mixin bg-tan {
		background: url(../images/site/tan-noise-bg.png) repeat top left;
	}

	@mixin ticket-edge-gray-dn {
		background: url(../images/site/ticket-edge-gray-dn.png) repeat-x top left;
	}

	@mixin ticket-edge-gray-up {
		background: url(../images/site/ticket-edge-gray-up.png) repeat-x top left;
	}

	@mixin ticket-edge-light-gray-dn {
		background: url(../images/site/ticket-edge-light-gray-dn.png) repeat-x top left;
	}

	@mixin ticket-edge-light-gray-up {
		background: url(../images/site/ticket-edge-light-gray-up.png) repeat-x top left;
	}

	@mixin ticket-edge-red-dn {
		background: url(../images/site/ticket-edge-red-dn.png) repeat-x top left;
	}

	@mixin ticket-edge-red-up {
		background: url(../images/site/ticket-edge-red-up.png) repeat-x top left;
	}

	@mixin ticket-edge-tan-dn {
		background: url(../images/site/ticket-edge-tan-dn.png) repeat-x top left;
	}

	@mixin ticket-edge-tan-up {
		background: url(../images/site/ticket-edge-tan-up.png) repeat-x top left;
	}

	@mixin bg-lines-diagonal {
		height: 12px;
		
		background: url(../images/site/bg-lines-diagonal.png) repeat-x top left;

		.svg & {
			background-image: url(../images/site/bg-lines-diagonal.svg);
		}
	}

	@mixin bg-lines-diagonal-gray {
		height: 12px;
		
		background: url(../images/site/bg-lines-diagonal-gray.png) repeat-x top left;

		.svg & {
			background-image: url(../images/site/bg-lines-diagonal-gray.svg);
		}
	}

	@mixin bg-lines-vertical {
		background: url(../images/site/bg-lines-vertical.png) repeat top center;

		.svg & {
			background-image: url(../images/site/bg-lines-vertical.svg);
		}
	}

	@mixin bg-lines-vertical-black {
		background: url(../images/site/bg-lines-vertical-black.png) repeat top center;

		.svg & {
			background-image: url(../images/site/bg-lines-vertical-black.svg);
		}
	}

	@mixin bg-dots {
		background: url(../images/site/bg-dots.png) repeat top center;

		.svg & {
			background-image: url(../images/site/bg-dots.svg);
		}
	}

	@mixin bg-texture-light {
		background-image: url(../images/site/rough-texture-light.png);
		background-repeat: no-repeat;
		background-position: center center;
	}

	@mixin bg-texture-dark {
		background-image: url(../images/site/rough-texture-dark.png);
		background-repeat: no-repeat;
		background-position: center center;
	}

	@mixin bg-texture-red {
		background-image: url(../images/site/rough-texture-orange.png);
		background-repeat: no-repeat;
		background-position: center center;
	}

	@mixin bg-texture-black {
		background-image: url(../images/site/rough-texture-black.png);
		background-repeat: no-repeat;
		background-position: center center;
	}


	/*	Social Media
	//------------------------------*/

	@mixin icon-facebook {
		@include icon-sprite(-250px, -160px);
		@include size(24);
	}

	@mixin icon-twitter {
		@include icon-sprite(-274px, -160px);
		@include size(24);
	}

	@mixin icon-instagram {
		@include icon-sprite(-298px, -160px);
		@include size(24);
	}


	/*	Labels
	//------------------------------*/

	@mixin icon-label-heart {
		@include size(26,26);
		@include icon-sprite(-260px, -300px);
	}

	@mixin icon-label-star {
		@include size(26,26);
		@include icon-sprite(-286px, -300px);
	}

	@mixin icon-label-wheat {
		@include size(26,26);
		@include icon-sprite(-312px, -300px);
	}

	@mixin icon-label-faucet {
		@include size(26,26);
		@include icon-sprite(-338px, -300px);
	}

	@mixin icon-label-drop {
		@include size(26,26);
		@include icon-sprite(-364px, -300px);
	}


	/*	Misc.
	//------------------------------*/

	@mixin icon-beer-finder {
		@include icon-sprite(0, -280px);
		@include size(50,60);
	}

	@mixin icon-beer-finder-filled {
		@include icon-sprite(-50px, -280px);
		@include size(50,60);
	}

	@mixin icon-cart {
		@include icon-sprite(-330px, -160px);
		@include size(27,20);
	}

	@mixin icon-contact {
		@include icon-sprite(-420px, -160px);
		@include size(30);
	}

	@mixin icon-bolt-right {
		@include icon-sprite(-250px, -200px);
		@include size(215, 46);
	}

	@mixin icon-bolt-left {
		@include icon-sprite(-250px, -250px);
		@include size(215, 46);
	}

	@mixin icon-stars {
		@include icon-sprite(-250px, -100px);
		@include size(80, 20);
	}

	@mixin icon-durham {
		@include icon-sprite(-250px, -120px);
		@include size(136, 33);
	}

	@mixin icon-bottle-12 {
		@include icon-sprite(-189px, -280px);
		@include size(14, 44);
	}

	@mixin icon-can-12 {
		@include icon-sprite(-100px, -280px);
		@include size(18, 44);
	}

	@mixin icon-can-16 {
		@include icon-sprite(-118px, -280px);
		@include size(19, 44);
	}

	@mixin icon-crowler {
		@include icon-sprite(-137px, -280px);
		@include size(23, 44);
	}

	@mixin icon-growler {
		@include icon-sprite(-160px, -280px);
		@include size(29, 44);
	}

	@mixin icon-on-tap-pint {
		@include icon-sprite(-210px, -280px);
		@include size(22, 44);
	}

	@mixin icon-on-tap-tulip {
		@include icon-sprite(-232px, -280px);
		@include size(22, 44);
	}

	@mixin icon-bottle-plain {
		@include icon-sprite(-470px, -280px);
		@include size(18, 44);
	}

	@mixin icon-divider {
		background: url(../images/site/divider.png) no-repeat center center;

		.svg & {
			background-image: url(../images/site/divider.svg);
		}

		width: 100%;
		max-width: 500px;
		height: 26px;
	}

	@mixin icon-glass {
		@include icon-sprite(-360px,-160px);
		@include size(16,20);
	}

	@mixin icon-place {
		@include icon-sprite(-380px,-160px);
		@include size(16,20);
	}

	@mixin icon-distance {
		@include icon-sprite(-400px,-160px);
		@include size(16,20);
	}

	@mixin icon-signature {
		@include size(340,96);

		background: url(../images/site/signature.png) no-repeat center center;

		.svg & {
			background-image: url(../images/site/signature.svg);
		}
	}

