/*------------------------------>
	Document > Forms
<------------------------------*/

	::-webkit-input-placeholder {
		color: #ccc;
	}

	
	::-moz-placeholder {
		color: #ccc;
	}
	
	:-ms-input-placeholder {
		color: #ccc;
	}
	
	:-moz-placeholder {
		color: #ccc;
	}

	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}

	input,
	textarea {
		border: none;
		border-radius: 0;
		padding: 0;

		-webkit-appearance: none;
	}

	input::-ms-clear {
		width: 0;
		height: 0;
	}

	input[type=text],
	input[type=email],
	input[type=tel],
	input[type=url],
	input[type=number],
	input[type=date],
	input[type=search],
	input[type=password],
	textarea {
		@include font-size(20);
		@include transition(0.25s, ease);

		background-color: $white;
		border: 1px solid transparentize($black, 0.75);
		letter-spacing: -0.01em;
		padding: 12px;
		outline: none;
		vertical-align: middle;

		&:focus {
			// border-color: ;
		}
	}

	textarea {
		line-height: 1.5;
		resize: vertical;
	}


	/*	Form Tabs
	//------------------------------*/

	.form-tabs {
		padding: 40px 0;
	}

	.form-tabs-menu {
		@include reset-list;

		border-bottom: 3px solid $gray-dark;
		margin-bottom: 30px;
	}

	.form-tabs-menu-item {
		display: inline-block;
	}

	.form-tabs-tab {
		@include button;
		@include verlag-condensed;
		@include font-size(18);
		@include font-smoothing;

		background-color: $tan-base;
		color: $gray-dark;
		padding: 20px;
		margin-right: 10px;
		text-transform: uppercase;

		&:last-child {
			margin-right: 0;
		}

		&.fs-swap-enabled {

			&.fs-swap-active {
				background-color: $gray-dark;
				color: $off-white;
			}
		}
	}

	.form-tabs-link-out {
		@include verlag;
		@include font-size(13);

		color: $gray-middle;
		display: inline-block;
		float: right;
		margin-bottom: 30px;
		opacity: 0.75;
		text-transform: uppercase;
		transition: opacity 0.2s linear;

		&:hover {
			opacity: 1;
		}
	}

	.form-tabs-section {
		&:not(.fs-swap-active) {
			@include offscreen;
		}
	}

	.form-tabs-embed {
		clear: both;
	}

	.select-wrapper {
		position: relative;
		z-index: 1;

		&:after {
			height: 0;
			width: 0;
			position: absolute;
			top: 0;
			right: 15px;
			bottom: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid $black;
			content: '';
			display: block;
			margin: auto 0
		}
	}

	select {
		@include verlag-black;

		width: 100%;
		height: 50px;

		background-color: $gray-light;
		border: 1px solid $gray-light;
		border-radius: 0;
		color: $black;
		outline: none;
		padding: 12px;
		text-transform: uppercase;
		transition: border-color 0.2s linear;
		white-space: nowrap;
		// -webkit-appearance: none;
	}


	/*	Formstone Overrides
	//------------------------------*/
	
	.fs-default {

		.fs-dropdown.fs-light {
			@include verlag-black;

			outline: none;

			.fs-dropdown-selected {
				height: 50px;

				background-color: $gray-light;
				border: 1px solid $gray-light;
				border-radius: 0;
				color: $black;
				outline: none;
				text-transform: uppercase;
				transition: border-color 0.2s linear;
				white-space: nowrap;

				.no-touchevents &:hover {
					background-color: $gray-light;
					border-color: $red;
					color: $black;

					&:after {
						border-top-color: $black;
					}
				}

				&:after {
					border-top-color: $black;
				}
			}

			.fs-dropdown-options {
				background-color: $gray-light;
				border-color: $gray-light;
				border-radius: 0;

				.fs-dropdown-item {
					background-color: $gray-light;
					color: $black;
					text-transform: uppercase;

					&.fs-dropdown-item_selected,
					.no-touchevents &:hover {
						background-color: $gray-dark;
						color: $red;
					}
				}
			}

			&.fs-dropdown-open {
				.fs-dropdown-selected {
					border-color: $black;

					&:after {
						border-top-color: $black;
					}
				}
			}
		}

		.fs-number.fs-light {
			@include verlag-black;

			border-radius: 0;

			&.fs-number-focus {

				.fs-number-element {
					border-color: $black;
				}
			}

			.fs-number-element {
				height: 50px;
				background-color: $gray-light;
				border: 1px solid $gray-light;
				border-radius: 0;
				color: $black;
				outline: none;
				text-transform: uppercase;
				transition: border-color 0.2s linear;

				.no-touchevents &:hover {
					border-color: $red;
				}
			}

			.fs-number-arrow {
				background: transparent;
				border: none;
				border-radius: 0;

				&.fs-number-up {
					&:after {
						border-bottom-color: $black;
					}
				}

				&.fs-number-down {
					&:after {
						border-top-color: $black;
					}
				}
			}
		}
	}

	.fs-checkbox.fs-light {

		.fs-checkbox-marker {
			background-color: $white;
			border-color: $gray-light;
		}

		&.fs-checkbox-focus,
		.no-touchevents &:hover {
			.fs-checkbox-marker {
				border-color: $red;
			}
		}

		.fs-checkbox-flag {

			&:before {
				border-color: $red;
			}
		}
	}

	.fs-light.fs-checkbox-focus .fs-checkbox-label,.no-touchevents .fs-light:hover .fs-checkbox-label {
		color: $black;
	}

	.fs-light.fs-checkbox-focus .fs-checkbox-marker,.no-touchevents .fs-light:hover .fs-checkbox-marker {
		background: $white;
		border-color: $gray-light;
	}

	.no-touchevents .fs-checkbox.fs-light:hover .fs-checkbox-marker {
		background: $white;
		border-color: $gray-light;
	}


	.fs-light.fs-checkbox-focus .fs-checkbox-flag:before,.no-touchevents .fs-light:hover .fs-checkbox-flag:before {
		border-color: $red;
	}

	.fs-light.fs-checkbox-radio.fs-checkbox-focus .fs-checkbox-flag,.no-touchevents .fs-light.fs-checkbox-radio:hover .fs-checkbox-flag {
		background: $red;
	}

	.fs-light.fs-checkbox-disabled .fs-checkbox-label,.no-touchevents .fs-light.fs-checkbox-disabled:hover .fs-checkbox-label {
		color: $gray-light;
	}

	.fs-light.fs-checkbox-disabled .fs-checkbox-marker,.no-touchevents .fs-light.fs-checkbox-disabled:hover .fs-checkbox-marker {
		background: #fff;
		border-color: $gray-light
	}

	.fs-light.fs-checkbox-disabled .fs-checkbox-flag:before,.no-touchevents .fs-light.fs-checkbox-disabled:hover .fs-checkbox-flag:before {
		border-color: $gray-light
	}

	.fs-light.fs-checkbox-radio.fs-checkbox-disabled .fs-checkbox-flag,.no-touchevents .fs-light.fs-checkbox-radio.fs-checkbox-disabled:hover .fs-checkbox-flag {
		background: $gray-light
	}
