/*------------------------------>
	Layout > Navigation
<------------------------------*/

	/*	Navigation Menu
	//------------------------------*/

	.navigation-menu {
		@include reset-list;

		display: inline-block;
		font-size: 0;
	}

	.navigation-item {
		@include reset-list;

		display: inline-block;
		vertical-align: middle;

		@include breakpoint($max-lg) {
			.header &:not(.header-logo-container):not(.shop-cart-item):not(.beer-finder-item):not(.contact-item) {
				display: none;
			}
		}

		&.shop-cart-item {
			border-left: 2px solid $gold;
			padding-left: 2px;

			@include breakpoint($max-lg) {
				position: absolute;
				right: 220px;
			}

			@include breakpoint($max-md) {
				right: 182px;

				border-left: none;
				padding-left: 0;
			}

			@include breakpoint($max-sm) {
				display: none;
			}

			@include breakpoint($min-lg) {
				margin-left: 5px;
			}

			@include breakpoint($min-xl) {
				margin-left: 20px;
			}
		}

		&.contact-item {
			@include breakpoint($max-lg) {
				position: absolute;
				right: 172px;
			}

			@include breakpoint($sm-md) {
				right: 141px;
			}

			@include breakpoint(401px 500px) {
				right: 100px;
			}

			@include breakpoint(0px 400px) {
				display: none;
			}
		}

		&.beer-finder-item {
			@include breakpoint($max-lg) {
				position: absolute;
				right: 121px;
			}

			@include breakpoint($max-sm) {
				display: none;
			}

			@include breakpoint($sm-md) {
				right: 100px;
			}
		}
	}

	.navigation-link {
		@include verlag-black;
		@include font-size(16);

		color: $gray-dark;
		display: block;
		line-height: 64px;
		padding: 0 13px;
		text-transform: uppercase;

		@include breakpoint($min-xl) {
			@include font-size(18);

			padding-left: 20px;
			padding-right: 20px;
		}

		.navigation-item.active & {
			color: $red;
		}

		&:hover {
			color: $red;
		}

		&.shop-cart-link {
			@include text-indent(64px);

			width: 60px;

			position: relative;

			border-left: 1px solid $gold;
			color: transparent;
			padding: 0;

			&:after {
				@include icon-cart;

				position: absolute;
				top: 0;
				bottom: 0;
				left: 20px;

				content: "";
				margin: auto 0;
			}
			
			.navigation-item.active &,
			&:hover {
				color: transparent;
			}

			.shop-cart-count {
				@include size(16);
				@include verlag-black;
				@include font-size(9);
				@include font-smoothing;

				position: absolute;
				top: 8px;
				right: 14px;
				z-index: 1;

				background: $red;
				border-radius: 100%;
				color: $white;
				line-height: 16px;
				text-align: center;
				text-indent: 0;
			}

			@include breakpoint($max-md) {
				height: 42px;

				border-left: none;

				.shop-cart-count {
					top: 4px;
					right: 10px;
				}
			}
		}

		&.contact-link {
			@include text-indent(64px);

			width: 50px;
			height: 60px;

			position: relative;

			color: transparent;
			padding: 0;

			.navigation-item.active &,
			&:hover {
				color: transparent;
			}

			&:before {
				@include icon-contact;

				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				content: "";
				margin: auto;
			}

			@include breakpoint($max-md) {
				height: 42px;

				&:before {
					@include scale-sprite(0.7, -420px, -160px);
					@include size(21);
				}
			}
		}

		&.beer-finder-link {
			@include text-indent(64px);

			width: 50px;
			height: 60px;

			position: relative;

			color: transparent;
			padding: 0;

			&:before,
			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				content: "";
				margin: auto;
				transition: opacity 0.2s linear;
			}

			&:before {
				@include icon-beer-finder-filled;

				opacity: 0;

				@include breakpoint($sm-md) {
					@include scale-sprite(0.55, -50px, -280px);
					@include size(27.5, 33);
				}
			}

			&:after {
				@include icon-beer-finder;

				@include breakpoint($sm-md) {
					@include scale-sprite(0.55, 0, -280px);
					@include size(27.5, 33);
				}
			}

			&:hover {
				&:before {
					opacity: 1;
				}

				&:after {
					opacity: 0;
				}
			}

			@include breakpoint($sm-md) {
				height: 42px;
				line-height: 42px;
			}
		}
	}


	/*	Subnavigation
	//------------------------------*/

	.subnavigation {
		height: 45px;

		position: relative;
		z-index: 0;

		background-color: $gray-dark;
		
		margin-top: 6px;

		text-align: center;

		@include breakpoint($min-lg) {
			overflow: hidden;
		}

		&:after {
			@include full-width;
			
			height: 5px;

			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			box-shadow: inset 0px 10px 10px -10px #222222;
			content: "";
			pointer-events: none;
		}

		&:before {
			width: 100%;
			height: 1px;

			position: absolute;
			top: 50%;
			left: 0;
			z-index: -1;

			background-color: $gray-dark;
			box-shadow: -1px 0 0 1px $gray-light;

			content: "";
			margin-top: -2px;
		}

		@include breakpoint($max-lg) {
			
			.fs-row {
				&, & .fs-cell {
					width: 100% !important;
					margin: 0;
				}
			}
		}
	}

	.subnavigation-menu {
		@include font-size(0);

		margin: 0;
		padding-left: 60px;
		padding-right: 60px;

		@include breakpoint($max-lg) {
			display: none;
		}
			
		@include breakpoint($min-lg) {
			margin-left: 68px;
			white-space: nowrap;
		}

		&.fs-navigation-enabled {
			padding: 0;
			outline: none;
		}

		&:hover {
			.subnavigation-menu-item {
				a {
					color: $gray-middle;
				}
			}
		}
	}

	.subnavigation-menu-item {
		background-color: $gray-dark;
		display: inline-block;

		&:first-child {
			padding-left: 15px;
		}

		&:last-child {
			padding-right: 15px;
		}

		a {
			height: 45px;
			@include verlag-black;
			@include font-size(14);
			@include font-smoothing;

			position: relative;
			
			color: $off-white;
			display: block;
			line-height: 45px;
			padding: 0 15px;
			text-transform: uppercase;

			&:after {
				@include size(0)

				position: absolute;
				bottom: 0;
				left: 50%;

				border-bottom: 6px solid transparent;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				content: "";
				margin-left: -10px;
			}

			.subnavigation-menu:hover &:hover,
			&:hover {
				color: $white;
			}

			@include breakpoint($min-xl) {
				padding: 0 25px;
			}
		}

		&.active a {

			&:after {
				border-bottom-color: $off-white;
			}
		}

		@include breakpoint($max-lg) {
			width: 100%;

			border-top: 1px solid $gray-banner;
		}
	}

	.subnavigation-handle.fs-navigation-handle {
		@include verlag-black;
		@include font-size(14);
		@include font-smoothing;

		width: auto;
		height: 45px;

		position: relative;
		
		background-color: $gray-dark;
		color: $off-white;
		display: inline-block;
		line-height: 45px;
		outline: none;
		padding: 0 45px 0 25px;
		text-transform: uppercase;

		@include breakpoint($min-lg) {
			display: none;
		}

		&:after {
			@include font-size(24);

			position: absolute;
			top: 0;
			bottom: 0;

			content: '+';
			margin-top: auto;
			margin-bottom: auto;
			margin-left: 8px;
			transition: transform 0.15s ease;
		}

		&.fs-navigation-open {
			&:after {
				transform: rotate(45deg);
			}
		}
	}


	/*	Mobile Navigation
	//------------------------------*/

	.page-wrapper.fs-navigation-overlay-content:before {
		content: none;
	}

	.mobile-navigation-handle,
	.mobile-navigation {

		@include breakpoint($min-lg) {
			display: none;
		}

		@media print {
			display: none !important;
		}
	}

	.mobile-navigation-handle {
		float: right;

		// Menu Button
		&.fs-navigation-overlay-handle.fs-navigation-enabled {
			@include verlag-black;
			@include font-size(20);
			@include font-smoothing;

			height: 64px;

			position: absolute;
			right: 0;
			top: 10px;
			z-index: 5;

			background: transparent;
			border: none;
			color: $red;
			cursor: pointer;
			display: block;
			line-height: 66px;
			margin: 0;
			outline: none;
			padding: 0 45px 0 15px;
			text-transform: uppercase;
			user-select: none;

			&:after {
				width: 20px;
				height: 3px;
				
				position: absolute;
				top: 0;
				right: 15px;
				bottom: 0;

				background: $red;
				box-shadow: 0 5px 0 $red, 0 -5px 0 $red;
				content: "";
				display: block;
				margin: auto 0;
				text-indent: 0;
			}

			// Menu Button Open
			&.fs-navigation-open {
				right: 10px;
				z-index: 20;

				background-color: transparent;
				color: $white;

				&:after {
					@include size(15);
					@include font-size(28);

					right: 24px;

					background-color: transparent;
					box-shadow: none;
					color: $white;
					content: "\00d7";
					line-height: 12px;
					text-align: center;
				}
			}

			&.fs-navigation-focus, 
			.no-touchevents &:hover {
				background-color: transparent;
			}

			@include breakpoint($md-lg) {
				top: 15px;
			}

			@include breakpoint($max-md) {
				@include font-size(16);
				
				height: 42px;
				
				line-height: 46px;				
			}

			@include breakpoint(0px 400px) {
				@include text-indent(64px);

				width: 64px;

				color: transparent;

				&.fs-navigation-open {
					color: transparent;

					&:after {
						right: 18px;
					}
				}
			}
		}
	}

	.fs-navigation-overlay-right-nav.fs-navigation-enabled {
		z-index: 10;
		transform: none;
	}

	.csstransforms3d .fs-light.fs-navigation-overlay-right-nav.fs-navigation-enabled{
		transform: none;
	}

	.fs-navigation-overlay-nav.fs-navigation-open {
		
	}

	.csstransforms3d .fs-light.fs-navigation-overlay-nav.fs-navigation-open {
		transform: none;
	}

	.mobile-navigation-menu {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.mobile-navigation {
		opacity: 0;
		outline: none;
		pointer-events: none;
		visibility: hidden;

		// Menu
		&.fs-navigation-overlay-nav.fs-navigation-enabled {
			width: 100%;
			height: 100%;

			z-index: 10;

			background-color: $red;
			color: $white;
			overflow: hidden;
			padding: 17px;
			text-align: left;
			transition: opacity 0.2s linear,
						visibility 0.2s linear;

			&:before {
				position: absolute;
				top: 10px;
				left: 10px;
				bottom: 10px;
				right: 10px;
				z-index: -1;

				border: 3px solid $blacker;
				content: "";
				display: block;
			}

			&:after {
				position: absolute;
				top: 16px;
				left: 16px;
				bottom: 16px;
				right: 16px;
				z-index: -1;

				border: 1px solid $gray-dark;
				content: "";
				display: block;
			}
		}

		// Menu Open
		&.fs-navigation-overlay-nav.fs-navigation-open {
			opacity: 1;
			pointer-events: all;
			visibility: visible;
		}

		.mobile-navigation-background {
			width: 100%;
			
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -2;

			padding-top: 28.9%;

			&:after {
				@include mobile-menu-logo-background;
				
				width: 102%;
				height: 100%;

				position: absolute;
				bottom: 0;
				left: 50%;
				z-index: 0;

				background-size: cover;
				background-position: top center;
				content: "";
				display: block;
				transform: translateX(-50%);
				
			}
		}

		.mobile-navigation-wrapper {
			height: 100%;

			font-size: 0;
			overflow-y: auto;
			overflow-x: hidden;
			
		}

		.mobile-navigation-item {
			@include verlag-condensed-black;
			@include font-size(44);
			@include font-smoothing;

			display: block;
			text-transform: uppercase;

			&:first-child {
				border: none;
			}

			a {
				color: $white;
				display: block;
				padding: 10px 0;

				&:hover {
					color: $blacker;
				}
			}
		}

		.mobile-navigation-menu {
			border-bottom: 1px solid $blacker;
			padding: 40px 20px 20px;

			@include breakpoint($min-sm) {
				padding: 60px 20px 20px;
			}

			@include breakpoint($min-md) {
				padding: 80px 30px 30px;
			}
		}

		.mobile-navigation-additional-menu {
			border-top: 1px solid $blacker;
			padding: 20px 20px 30px;

			@include breakpoint($min-md) {
				padding: 30px 30px 40px;
			}
		}

		.mobile-navigation-additional-item {
			@include verlag-condensed-black;
			@include font-size(30);
			@include font-smoothing;

			display: block;
			text-transform: uppercase;

			&:first-child {
				border: none;
			}

			a {
				color: $white;
				display: block;
				padding: 10px 0;

				&:hover {
					color: $blacker;
				}
			}
		}

		.mobile-hours {
			background-color: $blacker;
			padding: 20px 10px;
			text-align: center;

			@include breakpoint($min-md) {
				padding: 30px 20px;
			}

			.tavern-open-hours {
				@include font-size(20);
			}
		}

		.sub-menu {
			padding-bottom: 10px;

			.menu-item {

				a {
					@include font-size(16);

					color: $red;
					padding: 10px 20px;

					&:hover {
						color: $white;
					}
				}
			}
		}
	}
