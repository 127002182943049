/*------------------------------>
	Templates > Blog
<------------------------------*/

	.news {
		padding-top: 20px;
	}

	.news-list-item {

	}

	.news-list-item-date {
		border-right: 1px solid $gray-light;
		display: inline-block;
		padding: 10px 20px 10px 10px;
		vertical-align: middle;
	}

	.news-day,
	.news-month {
		@include verlag-black;
		@include font-smoothing;

		color: $gray-light;
		display: block;
		text-align: center;
	}

	.news-day {
		@include font-size(32);
	}

	.news-month {
		@include font-size(18);

		text-transform: uppercase;
	}

	.news-list-item-info {
		display: inline-block;
		padding: 10px 10px 10px 20px;
		vertical-align: middle;

		@include breakpoint($max-sm) {
			padding-left: 10px;
		}
	}

	.news-info {
		@include verlag-bold;
		@include font-size(15);

		color: $gray-middle;
		margin-top: 5px;
		text-transform: uppercase;
	}


	/*	Blog Landing
	//------------------------------*/

	.template-news-landing {
		
	}
	
	.news-featured-section {
		margin-bottom: 50px;

		@include breakpoint($min-md) {
			margin-bottom: 65px;
		}

		@include breakpoint($min-lg) {
			margin-bottom: 80px;	
		}

		.news-featured-container {
			@include breakpoint($sm-md) {
				padding-top: 130%;
			}

			@include breakpoint($md-lg) {
				padding-top: 120%;
			}

			@include breakpoint($min-lg) {
				@include breakpoint($max-ht) {
					padding-top: 66.67%;
				}
			}
		}

		.news-featured-heading {
			@include breakpoint($max-md) {
				@include font-size(26);
			}
		}

		.news-featured {
			
		}
	}

	.news-featured-carousel {
		&.fs-carousel.fs-carousel-enabled.fs-light {
			.fs-carousel-pagination {
				margin: 20px 0 0;
			}

			.fs-carousel-page {
				background-color: $red;
			}
		}
	}

	.news-featured-carousel-item {

		@include breakpoint($max-md) {

			.fs-grid .fs-row & {
				margin: 0;

				padding-right: 10px;
				padding-left: 10px;
			}
		}
	}

	.news-listing-section {

	}

	.news-categories {
		
	}

	.news-listing-list {
		.block-section-left &.block-list {
			@include breakpoint($min-lg) {
				transform: translateX(620px);
			}

			@include breakpoint($min-xl) {
				transform: translateX(775px);
			}
		}
	}

	.news-listing-item {

		.news-title {
			margin-bottom: 6px;
		}

		&.captains-journal {
			position: relative;
			z-index: 1;

			background-color: $black;
			overflow: hidden;
			transition: background-color 0.15s ease,
						padding .15s ease;

			&:after {
				@include icon-signature;

				position: absolute;
				right: 0;
				top: 50%;
				z-index: -1;

				content: "";
				margin-top: -48px;
				pointer-events: none;
				transition: opacity 0.15s ease;

				@include breakpoint($max-lg) {
					opacity: 0.3;
				}

				@include breakpoint($max-md) {
					top: auto;
					bottom: -10px;

					transform-origin: bottom right;
					transform: scale(0.7);
				}
			}

			&:hover {
				background-color: $black-gradient;

				&:after {
					opacity: 0.2;
				}
			}

			& + .captains-journal {
				border-top: 2px solid #525252;
			}

			.news-title {
				@include font-smoothing;

				color: #d7d7d7;
			}

			&:hover {
				.news-title {
					color: $white;
				}
			}

			.event-list-item-date {
				border-right-color: #525252;
			}

			.event-list-item-info {
				border-left-color: #525252;
			}
		}
	}

	.news-listing-item-content {
		display: inline-block;

		@include breakpoint($min-lg) {
			width: 625px;
		}

		@include breakpoint($min-xl) {
			width: 765px;
		}
	}

	.news-listing-item-info {

	}

	.news-listing-item-category {
		@include font-size(14);

		.captains-journal & {
			-webkit-font-smoothing: subpixel-antialiased;
			background-color: $red-dull;
			color: $white;
		}
	}

	.news-listing-categories {
		margin-top: 30px;
	}

	.news-listing-footer {
		.block-section-left &.block-footer {
			@include breakpoint($min-lg) {
				transform: translateX(620px);
			}

			@include breakpoint($min-xl) {
				transform: translateX(775px);
			}
		}
	}

	
	/*	Blog Archive
	//------------------------------*/

	.blog-archive {

		.blog-pagination {
			margin-top: 40px;
		}

		@include breakpoint($max-sm) {
			margin-bottom: 20px;
		}
	}

	.blog-archive-heading {
		text-align: center;
	}

	.blog-archive-posts {

		.blog-archive-subheading {
			@include heading-3;

			margin-bottom: 30px;

			@include breakpoint($max-sm) {
				@include font-size(24);
			}

			&,
			+ h3 {
				text-align: center;
			}

			+ h3 {
				@include font-size(24);

				@include breakpoint($max-sm) {
					@include font-size(20);
				}
			}
		}
	}

	.blog-category-description {
		margin-bottom: 30px;

		p {
			&, a {
				@include font-size(20);
			}

			text-align: center;
		}
	}

	.post-listing-row {
		&:nth-child(odd) {
			background-color: $tan-pale;
		}

		&.captains-journal {
			position: relative;
			z-index: 1;

			background-color: $black;
			overflow: hidden;

			&:after {
				@include icon-signature;

				position: absolute;
				right: 10px;
				bottom: 10px;
				z-index: -1;

				content: "";
				margin-top: -48px;
				opacity: 0.5;
				pointer-events: none;

				@include breakpoint($max-lg) {
					opacity: 0.3;
				}

				@include breakpoint($max-md) {
					top: auto;
					bottom: -5px;

					transform-origin: bottom right;
					transform: scale(0.7);
				}
			}

			& + .captains-journal {
				border-top: 8px solid #525252;
			}
		}
	}

	.post-listing {
		padding: 30px 15px;

		@include breakpoint($min-md) {
			padding: 50px 30px;
		}
	}

	.post-listing-image {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		padding-top: 66.667%;

		.post-category-list & {

			@include breakpoint($min-lg) {
				padding-top: 100%;	
			}
		}

		@include breakpoint($max-md) {
			margin-bottom: 20px;
		}
	}

	.post-listing-content {

		.post-category-list & {

			@include breakpoint($min-lg) {
				padding-left: 20px;
			}
		}
	}

	.post-listing-title {
		a {
			@include heading-3;

			color: $brown;
			line-height: 1.1;

			&:hover {
				color: $red;
			}

			@include breakpoint($max-md) {
				@include font-size(24);
			}
		}
		

		.post-category-list & {

			@include breakpoint($min-lg) {
				@include font-size(30);
			}
		}

		.captains-journal & a {
			@include font-smoothing;


			color: $white;

			&:hover {
				color: $red;
			}
		}
	}

	.post-listing-date {
		@include verlag-black;
		@include font-size(16);
		
		color: $gray-dark;
		display: inline-block;
		margin-top: 10px;
		text-transform: uppercase;

		.captains-journal & {
			@include font-smoothing;

			color: $gray-middle;
		}
	}

	.post-listing-categories {

	}

	.post-listing-category {
		display: block;
		float: left;
		margin: 10px 5px 0;

		&:first-child {
			margin-left: 0;
		}

		&:hover {
			background-color: $red;
		}

		.captains-journal & {
			background-color: #6E6E6E;

			&:hover {
				background-color: $red;
			}
		}

		
	}

	.post-listing-excerpt {
		margin-top: 20px;

		p {
			@include font-size(18);

			color: $gray-dark;

			@include breakpoint($min-md) {
				@include font-size(22);	
			}

			.captains-journal & {
				@include font-smoothing;

				color: $gray-pale;
			}
		}
	}

	.post-listing-button {
		background-color: $red;
		margin-top: 20px;

		&:hover {
			background-color: $off-white;
			color: $blacker;
		}
	}


	/*	Blog Entry
	//------------------------------*/

	.page-header-blog {
		height: 300px;

		@include breakpoint($min-sm) {
			height: 400px;
			max-height: 50vh;
		}

		@include breakpoint($min-md) {
			height: 500px;
			max-height: 60vh;
			min-height: 360px;
		}

		.page-header-container {
			text-align: center;

			h1 {
				@include font-size(32);

				@include breakpoint($min-sm) {
					@include font-size(40);
				}

				@include breakpoint($min-md) {
					@include font-size(50);
				}

				@include breakpoint($min-lg) {
					@include font-size(60);
				}

				@include breakpoint($min-xl) {
					@include font-size(70);

					padding: 0 30px;
				}
			}
		}
	}

	.blog-date {
		@include font-size(16);
		
		background-color: $red;
		margin-top: 20px;

		@include breakpoint($max-sm) {
			@include font-size(14);
		}
	}

	.article {

		@include breakpoint($min-sm) {
			padding: 0 20px;
		}

		@include breakpoint($min-md) {
			padding: 0;
		}
		@include breakpoint($min-lg) {
			padding: 0 115px;	
		}
	}
	
	.blog-article-footer {
		margin-top: 30px;
		border-top: 2px solid $gray-light;
		padding-top: 30px;

		@include breakpoint($min-md) {
			margin-top: 40px;
		}

		@include breakpoint($min-lg) {
			margin-top: 60px;
		}
	}

	.article-navigation-button {
		background-color: $red;

		&.article-next-button {
			float: right;
		}

		&:hover {
			background-color: $black;
		}

		@include breakpoint($max-sm) {
			@include font-size(16);

			padding: 10px 15px;
		}
	}


	/*	Home Page
	//------------------------------*/

	.news-home-featured {
		padding-top: 30px;
	}

	.news-featured {
		position: relative;
		z-index: 1;
		
		background-color: $gray-dark;
		background-size: cover;
		background-position: center center;
		transition: transform 0.1s ease-out;

		&:hover {
			@include breakpoint($min-md) {
				transform: scale(1.03);
			}
		}

		&:before,
		&:after {
			position: absolute;

			content: "";
			display: block;
		}

		&:before {
			top: 7px;
			left: 7px;
			bottom: 7px;
			right: 7px;
			z-index: -1;

			border: 3px solid #555;
		}

		&:after {
			@include full-width;
			@include gradient-vertical(rgba(38, 38, 38, 0), rgba(38, 38, 38, 1));

			height: 50%;

			bottom: 0;
			left: 0;
			z-index: -2;
		}
	}

	.news-featured-container {
		position: relative;
		
		display: block;
		padding-top: 90%;

		@include breakpoint($max-sm) {
			padding-top: 130%;
		}

		@include breakpoint(400px 500px) {
			padding-top: 104%;
		}

		@include breakpoint($sm-lg) {
			padding-top: 60%;
		}

		@include breakpoint($lg-xl) {
			padding-top: 120%;

			@include breakpoint($max-ht) {
				padding-top: 60vh;	
			}
		}
	}

	.news-featured-content {
		position: absolute;
		bottom: 0;
		left: 0;

		color: $white;
		padding: 30px; 
	}

	.news-featured-tag {
		background-color: $gray-light;
		color: $gray-dark;
		margin-bottom: 20px;
	}

	.news-featured-heading {
		@include verlag-condensed-black;
		@include font-size(60);
		@include font-smoothing;

		line-height: 1.1;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(32);
		}

		@include breakpoint(400px) {
			@include font-size(38);
		}
	}
