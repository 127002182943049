/*------------------------------>
	Document > General
<------------------------------*/

	html {
		font-size: $base-font-size + px;
		background-color: $tan-base;
		height: 100%;
	}

	html,
	body {
		
	}

	body {
		@include font-size(16);
		@include verlag;

		min-width: 300px;
		min-height: 100%;
		
		background-color: $off-white;
		color: $black;
		overflow-x: hidden;
	}

	::selection {
		background: #eee;
		text-shadow: none;
	}

	hr {
		width: 100%;
		height: 1px;

		background: #eee;
		border: none;
		clear: both;
		margin: 0;
		padding: 0;

	}

	iframe {
		border: none;
	}

	menu {
		margin: 0;
		padding: 0;
	}

	audio,
	canvas,
	iframe,
	img,
	svg,
	video {
		vertical-align: middle;
	}

	figure {
		margin: 0;
	}

	img {
		max-width: 100%;
	}