/*------------------------------>
	Components > Arrows
<------------------------------*/

	.block-arrow {
		@include size(60, 40);
		@include text-indent(40px);

		position: relative;

		background-color: $gray-light;
		color: transparent;
		display: block;

		&:hover {
			background-color: $red;
		}

		&:before,
		&:after {
			position: absolute;
			top: 0;
			bottom: 0;

			display: block;
			content: "";
			margin: auto;
			text-indent: 0;
		}

		&:before {
			@include size(24, 6);

			background-color: $off-white;
		}

		&:after {
			@include size(0);
			
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
		}

		&.arrow-right {
			&:before {
				left: 13px;
			}

			&:after {
				right: 13px;

				border-left: 10px solid $off-white;
			}
		}

		&.arrow-left {
			&:before {
				right: 13px;
			}

			&:after {
				left: 13px;

				border-right: 10px solid $off-white;
			}
		}

		@include breakpoint($max-sm) {
			@include size(40);

			&:before {
				@include size(14, 6);
			}

			&.arrow-right {
				&:before {
					left: 10px;
				}

				&:after {
					right: 8px;
				}
			}

			&.arrow-left {
				&:before {
					right: 10px;
				}

				&:after {
					left: 8px;
				}
			}
		}
	}