/*------------------------------>
	Templates > Login
<------------------------------*/
	
	.rich-text {
		.login-page-heading {
			color: $gray-dark;
		}

		.login-error-message {
			color: $red;
		}
	}

	.login-page-content {
		text-align: center;

		header {
			margin-bottom: 50px;
		}

		form {
			max-width: 400px;

			margin: 0 auto;
			position: relative;
		}

		input[type=password],
		input[type=text] {
			@include full-width;
			@include font-size(14);
			@include verlag-black;
			@include font-smoothing;

			height: 40px;

			border: 1px solid $tan-dark;
			line-height: 1;
			padding: 13px 95px 13px 12px;
			text-transform: uppercase;

			&:focus {
				border-color: $gray-dark;
			}
		}

		input[type=submit] {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;

			&:hover {
				background-color: $red;
			}
		}
	}
