/*------------------------------>
	Components > Labels
<------------------------------*/

	.label-bar {
		@include verlag-black;
		@include font-size(16);
		@include font-smoothing;

		height: 36px;

		position: relative;
		z-index: 1;

		color: $white;
		display: inline-block;
		line-height: 36px;
		margin: 5px;
		padding-left: 15px;
		padding-right: 15px;
		text-transform: uppercase;

		&:before {
			
		}

		&:before {
			position: absolute;
			top: 3px;
			right: 3px;
			bottom: 3px;
			left: 3px;

			border: 2px solid;
			content: "";
		}

		&-icon {
			@include size(36, 26);

			position: absolute;
			top: 5px;
			left: 5px;

			border-right: 1px solid;
			display: inline-block;

			&:before {
				@include size(26, 26);

				position: absolute;
				top: 0;
				left: 5px;

				content: "";
			}
		}

		&-on-tap {
			background-color: $red;

			.label-bar-icon{
				border-color: $black;

				&:before {
					@include icon-label-faucet;
				}
			}

			&:before {
				border-color: $black;
			}
		}

		&-limited {
			background-color: $blacker;

			.label-bar-icon{
				border-color: #656565;

				&:before {
					@include icon-label-star;
				}
			}

			&:before {
				border-color: #656565;
			}
		}

		&-seasonal {
			background-color: $green;

			.label-bar-icon{
				border-color: $yellow;

				&:before {
					@include icon-label-wheat;
				}
			}

			&:before {
				border-color: $yellow;
			}
		}

		&-to-go {
			background-color: $blacker;
			
			&:before {
				border-color: #656565;
			}
		}

		&-tavern-only {
			background-color: $teal;

			.label-bar-icon{
				border-color: $black;

				&:before {
					@include icon-label-drop;
				}
			}

			&:before {
				border-color: $black;
			}
		}
	}
