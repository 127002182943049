/*------------------------------>
	Templates > Media
<------------------------------*/

	.media-section {
		margin-bottom: 40px;
	}

	.media-header {
		padding: 0 0 40px;
		text-align: center;

		h2 {
			@include heading-3;

			color: $gray-middle;
		}
	}


	/*	File Downloads
	//------------------------------*/

	.media-files {
		@include breakpoint($min-sm) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	.media-file {
		width: 100%;

		margin: 0 0 30px;
		text-align: center;

		@include breakpoint($min-sm) {
			width: 45%;

			flex: 0 0 45%;
		}


		@include breakpoint($min-md) {
			width: 30%;

			flex: 0 0 30%;
		}

		&-title {
			@include verlag-black;
			@include font-size(18);

			color: $gray-dark;
			margin-bottom: 5px;
			text-transform: uppercase;
		}

		&-type {
			@include verlag-black;
			@include font-size(14);

			color: $gray-light;
			margin-bottom: 15px;
			text-transform: uppercase;
		}

		&-image {
			position: relative;
			z-index: 1;

			max-width: 300px;

			display: inline-block;
			margin: 0 auto 20px;

			&:before {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;

				content: '';
				transition: box-shadow 0.2s ease;
			}

			&:hover {

				&:before {
					box-shadow: inset 0 0 0 5px $red;
				}
			}
		}

		&-description {
			p {
				@include font-size(18);
			}

			margin-bottom: 20px;
		}

		&-download {
			background-color: $red;
			margin-bottom: 20px;

			&:hover {
				background-color: $black;
			}
		}
	}


	.media-links {
		border-top: 2px solid $tan-dark;
	}

	a.media-link {
		@include heading-5;
		@include font-size(18);

		position: relative;

		border-bottom: 2px solid $tan-dark;
		display: block;
		padding: 20px 0;
		transition: color 0.2s ease,
					padding 0.2s ease;

		&:after {
			@include size(16);

			position: absolute;
			top: 22px;

			border-top: 2px solid $gray-dark;
			border-right: 2px solid $gray-dark;
			content: '';
			display: inline-block;
			margin-left: 10px;
			transform: rotateZ(45deg);
			transform-origin: 8px;
			transition: border-color 0.2 ease;
		}

		&:hover {
			color: $red;
			padding-left: 20px;

			&:after {
				border-top-color: $red;
				border-right-color: $red;
			}
		}
	}
