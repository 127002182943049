/*------------------------------>
	Templates > Events
<------------------------------*/

	.event {

	}

	.event-list-item {

		@include breakpoint($max-sm) {
			padding-left: 0;
		}
	}

	.event-list-item-date {
		width: 75px;

		border-right: 1px solid $gray-border;
		display: block;
		float: left;
		margin-right: -1px;
		padding: 10px 15px;
		vertical-align: middle;

		@include breakpoint($max-sm) {
			padding: 8px 10px;
		}
	}

	.event-day,
	.event-month {
		@include verlag-black;
		@include font-smoothing;

		color: $gray-light;
		display: block;
		text-align: center;
	}

	.event-day {
		@include font-size(32);

		@include breakpoint($max-sm) {
			@include font-size(28);
		}
	}

	.event-month {
		@include font-size(18);

		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(16);
		}
	}

	.event-list-item-info {
		border-left: 1px solid $gray-border;
		display: table;
		padding: 10px 10px 5px 20px;
		vertical-align: middle;

		> div {
			display: table-cell;
		}
	}

	.event-categories {

		.event-landing-categories & {
			margin-top: 30px;
		}

		@include breakpoint($max-lg) {
			max-width: 400px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	.event-info {
		@include verlag-bold;
		@include font-size(15);

		color: $gray-middle;
		margin-top: 5px;
		text-transform: uppercase;

		> span {
			color: $red;
			margin-left: 0.5rem;
		}
	}

	/*	Events Landing
	//------------------------------*/

	.template-events-landing {
		
		.this-week {
			position: relative;
			z-index: 2;

			background-size: cover;
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}

	.this-week {

		&.ticket-red {
			@include bg-texture-red;
		}
	}

	.events-landing {

	}

	.events-calendar {

	}

	.events-home-heading {
		text-align: center;
	}

	.events-home-rtp-text {
		text-align: center;
	}

	.calendar {
		@include breakpoint($max-md) {
			display: none;
		}
	}

	.event-landing-list {
		margin-top: 30px;

		.event-category-list-item {
			.no-touchevents &:hover .block-list-item-title {
				color: $red;
			}

			.event-title {
				@include font-size(24);
			}
		}
	}

	.event-landing-day {
		@include verlag-black;
		@include font-size(18);
		@include font-smoothing;
		
		position: relative;


		background: $gray-dark;
		color: $off-white;
		padding: 12px 20px 12px 100px;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			@include font-size(16);

			padding: 10px 15px 10px 100px;
		}

		.day {
			position: absolute;
			top: 0;
			left: 0;

			background-color: $red;
			display: block;
			padding: 12px;

			@include breakpoint($max-sm) {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}


	/*	Events Category Listing
	//------------------------------*/

	.event-category-heading {
		text-align: center;
	}

	.event-category-list-item {
		transition: padding $default-speed ease;

		&:nth-child(odd) {
			background-color: $tan-pale;
		}

		.event-featured-content {
			padding: 10px 0;
		}

		.event-time {
			@include verlag-bold;
			@include font-size(15);

			color: $gray-middle;
			margin-top: 5px;
			text-transform: uppercase;
		}

		.no-touchevents &:hover {
			@include breakpoint($min-lg) {
				padding-left: 15px;
			}
		}

		.event-list-item-info {
			&:first-child {
				border-left: none;
			}

			.template-events-landing & {
				padding-bottom: 10px;
			}
		}
	}

	.events-pagination {
		.pagination {

			@include breakpoint($min-lg) {
				text-align: left;	
			}
		}
	}

	.events-category-sidebar {
		text-align: center;
	}

	.events-category-back-button {
		text-align: center;

		&:hover {
			background-color: $red;
		}

		@include breakpoint($max-lg) {
			margin: 0 auto;
		}

		@include breakpoint($min-lg) {
			width: 100%;

			margin-bottom: 30px;
		}
	}


	/*	Events Entry
	//------------------------------*/

	.event-entry {
		position: relative;
		z-index: 1;
	}

	.event-details {
		@include bg-texture-black;

		margin-top: 0;

		@include breakpoint($max-lg) {
			margin-top: 30px;
		}

		.ticket-content {
			color: $white;
		}
	}

	.event-featured-header {
		background-color: transparent;
		border-bottom: none;

		.event-details-heading {
			@include font-size(28);

			color: $gray-border;
		}
	}

	.event-featured-content.ticket-content {
		@include verlag-condensed-black;
		@include font-smoothing;

		border-top: none;
		padding: 20px;
		
		a {
			color: $red;

			&:hover {
				color: $gray-border;
			}
		}

		h4 {
			@include font-size(30);

			color: $white;
			line-height: 1.2;
		}

		.event-entry-time {
			@include font-size(18);

			color: $gray-light;

			margin-bottom: 10px;
		}

		time {
			@include verlag-bold;
			@include font-size(18);

			color: $gray-middle;
			margin-top: 5px;
			text-transform: uppercase;


		}

		.events-home-featured & {
			h4, time {
				@include font-size(16);
			}
		}

		.event-entry-location {
			@include font-size(18);

			color: $gray-pale;
		}

		.recurring {
			@include verlag-black;
			@include font-size(12);

			color: $gray-middle;
			margin-top: 15px;
		}
	}

	.event-entry-header {
		@include clearfix();

		padding-bottom: 60px;

		&:after {
			@include full-width;
			@include bg-lines-vertical;

			width: 200%;
			height: 10px;

			position: absolute;
			left: -50%;
			z-index: -1;
			// bottom: -20px;

			content: "";
			margin-top: 20px;
		}

		@include breakpoint($md-lg) {
			margin-top: 20px;
		}
	}

	.event-entry-heading {
		@include page-heading;
		margin-bottom: 10px;

		@include breakpoint($min-lg) {
			@include font-size(56);
		}
	}

	.event-entry-type {
		background-color: $red;
	}

	.event-entry-description {
		p, ul li, ol li {
			@include font-size(18);

			@include breakpoint($min-md) {
				@include font-size(20);
			}
		}

		a {
			@include font-size(18);

			@include breakpoint($min-md) {
				@include font-size(20);
			}
			@include breakpoint($min-lg) {
				@include font-size(20);
			}
		}
	}


	/*	Home Page
	//------------------------------*/

	.events-home {
		overflow: hidden;

		.block-section + .block-section {
			margin-top: 6rem;
		}
	}

	.events-home-featured {

		@include breakpoint($md-lg) {
			margin-bottom: 15px;
		}

		@include breakpoint($min-lg) {
			// margin-top: -120px;
		}
	}

	.event-featured {
		@include bg-texture-black;

		&.events-callout {
			max-width: 640px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	.event-featured-calendar-bar {
		@include verlag-black;
		@include font-smoothing;

		background-color: #555;
		color: $white;
		padding: 0.5rem 1rem;
		text-transform: uppercase;
	}

	.event-featured-header {
		border: 1px solid #555;
		padding: 20px;

		@include breakpoint($md-lg) {
			width: 100%;
			display: table;
		}
	}

	.event-featured-info {

		@include breakpoint($md-lg) {
			display: table-cell;
			vertical-align: middle;
		}
	}

	.event-featured-heading {
		@include font-size(32);


		@include breakpoint($md-lg) {
			@include font-size(28);

			display: table-cell;
			vertical-align: middle;
		}

		@include breakpoint($max-sm) {
			@include font-size(22);
		}
	}

	.event-featured-content.ticket-content {
		line-height: 1.6;
		text-align: center;

		time {
			@include verlag-black;
			@include font-size(15);

			color: $gray-light;
			text-transform: uppercase;
		}
		h4 {
			@include verlag-black;
			@include font-size(20);
			@include font-smoothing;

			color: $gray-light;	
			line-height: 1.4;		
			text-transform: uppercase;

			@include breakpoint($max-sm) {
				@include font-size(12);
			}
		}

		.event-title {
			margin-bottom: 0;
		}

		.event-info {
			margin-top: 0;
		}

		@include breakpoint($md-lg) {
			@include full-width;

			display: table;
		}

		@include breakpoint($max-sm) {
			padding: 10px;
		}
	}

	.event-featured-footer {
		padding-top: 5px;
	}

	.event-featured-button {
		background-color: #a9a49c;
		color: $gray-dark;
		display: block;

		&:hover {
			background-color: $off-white;
			color: $gray-dark;
		}

		@include breakpoint($max-sm) {
			@include font-size(20);
		}

		.ticket-red & {
			background-color: $gray-dark;
			color: $white;

			&:hover {
				background-color: $off-white;
				color: $gray-dark;
			}
		}
	}
