/*------------------------------>
	Layout > Page Header
<------------------------------*/

	/*	Page Header (General)
	//------------------------------*/

	.page-header {
		@include full-width;

		height: 360px;

		@include breakpoint($min-sm) {
			height: 400px;
			max-height: 50vh;
		}

		@include breakpoint($min-md) {
			height: 650px;
			max-height: 60vh;
			min-height: 480px;

			&.page-header-slim {
				height: 500px;
			}
		}

		position: relative;
		z-index: 1;

		background-color: $gray-dark;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		border-bottom: 30px solid $red;
		overflow: hidden;

		&.darken {
			&:before {
				@include full;

				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;

				background-color: rgba(0,0,0,0.5);
				content: "";
				transition: background 0.2s linear;

				@include breakpoint($max-sm) {
					background-color: rgba(0,0,0,0.7);
				}
			}
		}

		&.js-background {

			&:before {
				z-index: 1;
			}
		}
	}

	.page-header-foreground-image {
		width: auto;
		height: auto;
		max-width: 80%;
		
		margin: 0 auto;

		@include breakpoint($max-md) {
			max-height: 200px;
		}

		@include breakpoint($min-sm) {
			max-width: 70%;
		}

		@include breakpoint($min-md) {
			max-width: 60%;
			max-height: 300px
		}

		@include breakpoint($min-lg) {
			max-width: 100%;
			max-height: 320px;

			margin-bottom: 3em;
		}

		@include breakpoint($min-xl) {
			
		}

		@include breakpoint($max-ht) {
			max-height: 300px;

			margin-bottom: 1em;
		}
	}

	.page-header-heading {
		text-align: center;
	}

	.page-header-container {
		position: relative;
		text-align: center;

		@include breakpoint($max-sm) {
			padding: 0 25px;
		}

		.page-header-slim & {
			h1 {
				@include font-size(32);

				@include breakpoint($min-sm) {
					@include font-size(40);
				}

				@include breakpoint($min-md) {
					@include font-size(50);
				}

				@include breakpoint($min-lg) {
					@include font-size(60);
				}

				@include breakpoint($min-xl) {
					@include font-size(70);

					padding: 0 30px;
				}
			}
		}
	}

	.page-header-content {
		@include full;

		position: relative;
		z-index: 1;

		padding-top: 95px;

		.display-table-cell {
			vertical-align: middle;
		}
		
		@include breakpoint($max-md) {
			padding-top: 65px;
		}

		h1 {
			@include font-size(36);
			@include verlag-condensed-black;
			@include font-smoothing;
			@include text-shadow(5, 5, 0, rgba(0,0,0,0.52));

			position: relative;
			z-index: 1;

			color: $off-white;
			letter-spacing: 2px;
			line-height: 1;
			text-align: center;
			text-transform: uppercase;

			@include breakpoint($max-md) {
				margin-top: 32px;
			}

			@include breakpoint($min-sm) {
				@include font-size(56);
			}

			@include breakpoint($min-md) {
				@include font-size(72);

				margin-top: 48px;
			}

			@include breakpoint($min-lg) {
				@include font-size(86);
			}

			@include breakpoint($min-xl) {
				@include font-size(100);
			}
		}
	}

	.page-header-button {
		background-color: $red;
		margin: 0 15px 20px;

		&:hover {
			background-color: $blacker;
		}
	}
