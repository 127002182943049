/*------------------------------>
	Components > Ticket
<------------------------------*/

	.ticket {
		position: relative;
		z-index: 1;

		margin-bottom: 30px;
		text-align: center;

		@include breakpoint($max-md) {
			max-width: 400px;
			margin-right: auto;
			margin-left: auto;
		}

		&:before,
		&:after {
			@include full-width;

			height: 6px;

			position: absolute;
			left: 0;

			content: "";
			display: block;
		}

		&:before {
			bottom: 100%;
		}

		&:after {
			top: 100%;
		}

		.ticket-container {
			position: relative;
			z-index: 1;

			padding: 10px 16px;

			&:before {
				position: absolute;
				top: 3px;
				left: 9px;
				bottom: 3px;
				right: 9px;
				z-index: -1;

				border: 3px solid #555;
				content: "";
				display: block;
			}
		}

		.ticket-header {
			border: 1px solid #555;
			padding: 20px;

			@include breakpoint($md-lg) {
				width: 100%;
				display: table;
			}
		}

		.ticket-heading {
			@include verlag-condensed-black;
		}

		.ticket-heading,
		.ticket-content {
			text-transform: uppercase;
		}

		.ticket-content {
			border: 1px solid #555;
			padding: 20px;
			text-align: left;
		}

		.ticket-footer {

		}

		&-red {
			background-color: $red;
			
			&:before {
				@include ticket-edge-red-up;
			}

			&:after {
				@include ticket-edge-red-dn;
			}

			.ticket-container:before,
			.ticket-header,
			.ticket-content {
				border-color: $blacker;
			}
		}

		&-gray {
			background-color: $gray-dark;

			&:before {
				@include ticket-edge-gray-up;
			}

			&:after {
				@include ticket-edge-gray-dn;
			}

			.ticket-heading {
				@include font-smoothing;

				color: $white;
			}
		}

		&-light-gray {
			background-color: $gray-fringe;

			&:before {
				@include ticket-edge-light-gray-up;
			}

			&:after {
				@include ticket-edge-light-gray-dn;
			}

			.ticket-container:before,
			.ticket-header,
			.ticket-content {
				border-color: $blacker;
			}

			.ticket-content {
				color: $blacker;
			}

			.ticket-fill {
				@include font-smoothing;
				
				background-color: $blacker;
				color: $white;
			}
		}
	}




	.category-block {
		@include bg-texture-red;

		position: relative;
		z-index: 2;

		margin-bottom: 30px;

		@include breakpoint($max-lg) {
			margin-top: 40px;
		}

		.ticket-header {
			background-color: $blacker;

		}

		.ticket-heading {
			@include heading-3;
			@include font-smoothing;

			color: $white;
			line-height: 1;
		}
	}

	.category-block-category {
		@include verlag-condensed-black;
		@include font-size(18);

		a {
			@include font-smoothing;

			color: $white;
			display: block;
			float: left;

			&:hover {
				color: $black;
			}
		}

		.post-count {
			color: $black;
			float: right;
		}

		@include breakpoint($min-lg) {
			@include font-size(20);
		}
	}
