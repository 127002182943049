/*------------------------------>
	Layout > Footer
<------------------------------*/

	.footer {
		@include bg-tan;

		position: relative;

		background-color: $tan-base;

		margin-top: 6px;
		padding: 30px 0 0;

		&:before,
		&:after {
			@include full-width;

			left: 0;

			content: "";
			display: block;
		}

		&:before {
			@include ticket-edge-tan-up;

			height: 6px;

			position: absolute;
			bottom: 100%;
		}

		&:after {
			height: 5px;

			position: absolute;
			top: 4px;

			background: transparent;
			border-top: 2px solid $gold;
			border-bottom: 1px solid $gold;
		}
	}


	/*	Footer Logo + Badge
	//------------------------------*/

	.footer-logo-container {
		position: relative;

		text-align: center;

		&:before,
		&:after {
			@include full-height;
			
			width: 5px;
			
			position: absolute;
			top: 0;

			background: transparent;
			border-left: 2px solid $gold;
			border-right: 1px solid $gold;
			content: "";
			display: block;
		}

		&:before {
			@include breakpoint($max-md) {
				display: none;
			}

			@include breakpoint($min-lg) {
				left: -30px;
			}
		}

		&:after {
			@include breakpoint($max-lg) {
				display: none;
			}

			@include breakpoint($min-lg) {
				right: -20px;
			}
		}

		@include breakpoint($max-md) {
			border-bottom: 2px solid $gold;
			padding-bottom: 20px;
		}
	}

	.footer-logo {
		@include footer-logo;
		@include text-indent(52px);

		display: inline-block;
		margin-top: 20px;
		margin-bottom: 20px;

		@include breakpoint($max-sm) {
			@include size(260,44);
			@include scale-sprite(0.74285714, 0, -37px);
		}

		@include breakpoint($lg-xl) {
			@include size(300,44);
			@include scale-sprite(0.85714286, 0, -37px);
		}
	}

	.footer-badge-container {
		@include breakpoint($max-md) {
			text-align: center;
		}
	}

	.footer-badge {
		@include footer-badge;

		display: block;
		float: right;
		margin-top: 10px;
		margin-right: 20px;

		@include breakpoint($max-md) {
			float: none;
			margin: 20px auto;
		}
	}


	/*	Newsletter + Social
	//------------------------------*/

	.footer-social-container {
		padding-left: 15px;

		@include breakpoint($max-lg) {
			text-align: center;
			margin-top: 30px;
			padding-left: 0;
		}

		@include breakpoint($max-md) {
			margin-top: 15px;
		}
	}

	.footer-newsletter {
		border-bottom: 2px solid $gold;
		margin-bottom: 24px;
		margin-top: 10px;
		padding: 0 15px 25px 15px;

		@include breakpoint($max-lg) {
			max-width: 500px;
			margin-right: auto;
			margin-left: auto;
		}

		@include breakpoint($max-sm) {
			margin-top: 5px;
		}

		.response {
			@include font-size(18);

			line-height: 1.4;
			padding: 15px 0;
		}

		#mc_embed_signup div.mce_inline_error {
			background-color: $red-bright;
			font-weight: 400;
			margin: 5px 0 0;
			text-transform: uppercase;
		}
	}

	.footer-newsletter-text {
		@include verlag-black;
		@include font-size(16);

		color: $gray-dark;
		display: inline-block;
		margin-bottom: 6px;
		text-transform: uppercase;
		vertical-align: middle;

		@include breakpoint($max-sm) {
			@include font-size(12);

			margin-bottom: 10px;
		}
	}

	.footer-newsletter-form {
		position: relative;

		display: block;
	}

	input.footer-newsletter-input {
		@include full-width;
		@include font-size(18);
		@include verlag;

		height: 40px;

		border: none;
		line-height: 1;
		padding: 13px 95px 13px 12px;
	}

	.footer-newsletter-submit {
		@include button-block-medium;

		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;

		&:hover {
			background-color: $red;
		}
	}

	.footer-social {
		padding-left: 15px;
		padding-right: 15px;

		@include breakpoint($max-lg) {
			text-align: center;
		}
	}

	.footer-social-text {
		@include verlag-condensed-black;
		@include font-size(30);

		color: $gray-dark;
		display: inline-block;
		margin-right: 20px;
		text-transform: uppercase;
		vertical-align: middle;

		@include breakpoint($max-md) {
			display: block;
			margin-bottom: 10px;
		}

		@include breakpoint($lg-xl) {
			display: block;
			margin-bottom: 10px;
		}
	}

	.social-link {
		@include size(48, 44);
		@include text-indent(44px);

		position: relative;

		background-color: $red;
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: $black;
		}

		&:after {
			position: absolute;
			top: 10px;
			left: 12px;

			content: "";
			display: block;
		}

		&.social-facebook:after {
			@include icon-facebook
		}

		&.social-twitter:after {
			@include icon-twitter;
		}

		&.social-instagram:after {
			@include icon-instagram;
		}

	}


	/*	Address
	//------------------------------*/

	.footer-contact {
		text-align: center;
		margin-bottom: 20px;
	}

	.footer-address,
	.footer-phone {
		@include font-size(18);
		@include verlag-black;
		@include font-smoothing;

		color: $gray-middle;
		text-transform: uppercase;

		.no-touchevents &:hover {
			color: $red;
		}
	}


	/*	Footer Navigation
	//------------------------------*/

	.footer-navigation {
		position: relative;

		background-color: $tan-dark;
		margin: 40px 0 10px;
		padding: 8px 0;
		text-align: center;

		&:before,
		&:after {
			@include full-width;
			
			height: 5px;
			
			position: absolute;
			left: 0;

			background: transparent;
			border-top: 2px solid $gold;
			border-bottom: 1px solid $gold;
			content: "";
			display: block;
		}

		&:before {
			top: -10px;
		}

		&:after {
			bottom: -10px;
		}

		&:hover {
			.footer-navigation-link {
				color: transparentize($gray-dark, 0.50);

				&:hover {
					color: $gray-dark;
				}
			}
		}
	}

	.footer-navigation-link {
		@include font-size(14);
		@include verlag-black;
		@include font-smoothing;

		color: $gray-dark;
		display: block;
		line-height: 40px;
		padding: 0 20px;
		text-transform: uppercase;

		&:hover {
			color: $gray-dark;
		}
	}

	.footer-navigation-button {
		margin-left: 10px;

		&:hover {
			background-color: $red;
		}
	}


	/*	Footer Meta
	//------------------------------*/

	.footer-meta {
		position: relative;
		z-index: 1;

		padding: 55px 0 40px;
		text-align: center;

		@include breakpoint($min-md) {
			padding: 85px 0 70px;
		}

		&:after {
			@include footer-logo-background;

			position: absolute;
			bottom: 0;
			left: 50%;
			z-index: -1;

			content: "";
			display: block;
			transform: translateX(-50%);
		}

		.pride-joy {
			@include verlag-black;
			@include font-size(26);

			position: relative;

			padding-bottom: 80px;
			text-transform: uppercase;

			.durham {
				@include icon-durham;
				@include text-indent(15px);

				display: block;
				margin: 10px auto 16px;
				vertical-align: middle;

				@include breakpoint($min-lg) {
					display: inline-block;
					margin: -10px 16px 0;
				}
			}

			&:after {
				@include icon-stars;

				position: absolute;
				left: 50%;
				bottom: 30px;

				content: "";
				display: inline-block;
				margin-left: -40px;
			}
		}

		.credits {

			p {
				@include verlag-black;
				@include font-size(10);

				color: $gray-dark;
				line-height: 1.8;
				margin: 0;
				text-transform: uppercase;
			}
		}
	}