/*------------------------------>
	Components > Callouts
<------------------------------*/

	/*	Product Callout
	//------------------------------*/

	.callout-product {
		position: relative;

		background-color: transparent;
		margin-top: 80px;
		margin-bottom: 65px;
		padding: 10px 0;

		@include breakpoint($max-sm) {
			margin-top: 40px;
		}
	}

	.callout-product-wrapper {
		border-bottom: 2px solid $true-black;
		border-top: 2px solid $true-black;
		padding: 6px 0;
	}

	.callout-product-container {
		@include bg-lines-vertical-black;
		@include clearfix;

		position: relative;
		z-index: 1;

		border-bottom: 2px solid $true-black;
		border-top: 2px solid $true-black;
		padding: 100px 0;
	}

	.callout-product-plate {
		@include box-shadow;
		@include bg-tan-light;

		position: relative;
		
		background-color: $off-white;
		padding: 55px 70px;
		text-align: center;

		&:before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			border: 10px solid $tan-dark;
			content: "";
			pointer-events: none;
		}

		@include breakpoint($max-sm) {
			padding: 250px 30px 45px;
		}
	}

	.callout-product-image {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);

		@include breakpoint($max-sm) {
			max-width: 150px;
			top: 50px;
			left: 0;

			margin: 0 auto;
			transform: none;

			img {
				width: 100%;
				height: auto;
			}
		}

		@include breakpoint($sm-md) {
			max-width: 200px;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	/*	Header */

	.callout-product-header {
		&:after {
			@include icon-divider;

			margin: 20px auto;
			content: "";
			display: block;
		}
	}

	.callout-product-subheading {
		@include verlag-black;
		@include font-size(14);
		@include font-smoothing;

		position: absolute;
		top: 0;
		left: 50%;

		background-color: $tan-dark;
		color: $gray-dark;
		display: inline-block;
		margin: 5px auto 0;
		padding: 8px 15px;
		text-transform: uppercase;
		transform: translate(-50%, -50%);

		@include breakpoint($max-sm) {
			width: 80%
		}
	}

	.callout-product-heading {
		@include verlag-condensed-black;

		color: $gray-dark;
		text-transform: uppercase;

		@include breakpoint($min-lg) {
			@include font-size(56);
		}

		@include breakpoint($min-xl) {
			@include font-size(76);
		}
	}

	.callout-product-text {

		p {
			@include breakpoint($min-lg) {
				@include font-size(22);	
			}
		}
	}

	.callout-product-footer {
		margin-top: 30px;
	}

	.callout-product-page-button {

		&:hover {
			background-color: $gray-middle;
		}

		@include breakpoint($max-md) {
			margin-bottom: 10px;
		}
	}

	.callout-product-shop-button {
		background-color: $red;

		&:hover {
			background-color: $red-bright;
		}

		@include breakpoint($min-md) {
			.callout-product-page-button + & {
				margin-left: 20px;
			}	
		}	
	}
